import TextField from '@mui/material/TextField';

import { IInput } from './types';

export default function Input({ variant = 'outlined', size = "small", ...props }: IInput) {
  return (
    <TextField
      {...props}
      variant={variant}
      size={size}
    />
  );
}
