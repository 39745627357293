import { SyntheticEvent } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardContent from '@mui/material/CardContent';
import { Divider, Typography } from '@mui/material';

import { ISite } from 'utils/types';

interface ITrialSwitcher {
  onClose: () => void;
  sites: ISite[];
  handelSiteChange: (e: SyntheticEvent, id: string) => void;
  selected: string;
};

function TrialsSwitcher({
  onClose,
  sites,
  handelSiteChange,
  selected,
}: ITrialSwitcher) {
  return (
    <Card>
      <CardHeader
        subheader="Show hide site list"
        action={
          <IconButton aria-label="settings" onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ bgcolor: 'grey.200', display: 'flex', flexDirection: 'column', maxHeight: 400, overflowY: 'auto' }}>
        <Typography variant="body1" color="primary" sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 0' }}>
          <span>Trial Name</span>
          <span>Trial Visibility</span>
        </Typography>
        <Divider />
        {sites.map(site => (
          <>
            <FormControlLabel
              control={<Switch checked={selected === site.id} />}
              key={site.id}
              label={site.name || ''}
              labelPlacement="start"
              onChange={(e) => handelSiteChange(e, site.id)}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                margin: 0,
                padding: '5px 0',
              }}
            />
            <Divider />
          </>)
        )}
      </CardContent>
    </Card >
  );
}

export default TrialsSwitcher;
