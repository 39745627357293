// @ts-nocheck

import { makeStyles } from '@mui/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 'auto',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    marginLeft: -drawerWidth,

  },
  contentShift: {
    [theme.breakpoints.up('md')]: {

      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
  detectionsHolder: {
    [theme.breakpoints.up('md')]: {
      width: 820,
    },
    width: 620,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: 'calc(100vh - 152px)',
    overflowY: 'auto',
  },

  tabRoot: {
    background: theme.palette.info.dark,
    marginRight: 5,
    borderRadius: theme.shape.borderRadius,
  },
  tabRootDisabled: {
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    marginRight: 5,
    borderRadius: theme.shape.borderRadius,
  },
  textColorInherit: {
    color: 'white',
  },
  tabSelected: {
    boxShadow: theme.shadows[11],
    background: theme.palette.warning.dark,
    color: 'white',
    opacity: 1,
  },
  indicator: {
    display: 'none',
  },
  tabPanel: {
    marginTop: 10,
    width: '100%',
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tabPanelContent: {
    padding: 10,
  },
  tabLabel: {
    marginRight: 5,
  },
  tabLabelCaption: {
    marginTop: -9,
  },
  tabsRoot: {
    marginBottom: 5,
  },
  formControl: {
    margin: '12px 0',
    minWidth: 250,
    '& .MuiOutlinedInput-root': {
      background: 'white',
    },
  },
  formControlLabel: {
    background: 'white',
  },
  forms: {
    padding: 10,
  },
  bulletChart: {
    height: 150,
    marginTop: 20,
  },
  columnChart: {
    marginTop: 20,
  },
  tabPanelHeader: {
    background: theme.palette.grey[300],
    color: theme.palette.grey[600],
    padding: '5px 10px',
    marginTop: 10,
  },
  tabPanelFooter: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 10,
    },
  },
  detectionImage: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  detectionImageWrapper: {
    width: 350,
    height: 350,
    borderRadius: '50%',
    overflow: 'hidden',
    background: '#000',
    position: 'relative',
    '& img': {
      width: '100%',
    },
  },
}));

export default useStyles;
