import {
  useRouteMatch,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
// import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import useStyles from './styles';
import { IRoutItem } from 'routes/types';
import { Typography } from "@mui/material";

const icons = [
  <FormatListBulletedIcon fontSize="small" sx={{ marginRight: '4px' }} />,
  <BookmarkAddOutlinedIcon fontSize="small" sx={{ marginRight: '4px' }} />,
];

function Trials({ subRoutes }: { subRoutes: IRoutItem[] }) {
  const classes = useStyles();
  const match = useRouteMatch();
  const reversedSubRoutes = [...subRoutes].reverse()

  return (
    <div className={classes.root}>
      <div className={classes.breadcrumbWrapper}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{}}>
          {reversedSubRoutes?.map((route, index) => (
            <NavLink
              className={classes.breadCrumbItem}
              to={`${match.url}${route.path}`}
              key={route.path}
              activeClassName={classes.activeBreadcrumb}
            >

              <Typography sx={{ display: 'flex', alignItems: 'center' }}> {icons[index]}{route.title}</Typography>
            </NavLink>
          ))}
        </Breadcrumbs>
      </div>
      <Switch>
        {subRoutes?.map(({ path, key, component: Component, subRoutes, exact }: IRoutItem) => (
          <Route
            key={key}
            path={`${match.path}${path}`}
            exact={exact}
            // @ts-ignore
            render={routeProps => (<Component {...routeProps} subRoutes={subRoutes} />)}
          />
        ))}
        <Redirect to={`${match.path}${subRoutes[subRoutes.length - 1].path}`} />
      </Switch>
    </div >
  );
}

export default Trials;
