import { useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';

import MoreIcon from '@mui/icons-material/MoreVert';

import { useColmnStyles } from './styles';
import ProgressBar from 'components/ProgressBar';
import { useHistory } from 'react-router';

function ColItem({ patient }: { patient: { [key: string]: any } }) {
  const classes = useColmnStyles();
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChooseSubject = ({ id, matchPercentage }: any) => {
    push(`/subjects/${id}`, { patientId: id, matchPercentage });
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Add note</MenuItem>
      <MenuItem onClick={handleMenuClose}>Add label</MenuItem>
      <MenuItem onClick={handleMenuClose}>Add subject ID</MenuItem>
    </Menu>
  );
  return (
    <div className={classes.patientItem} onClick={() => handleChooseSubject({ id: patient.id, matchPercentage: patient.matchPercentage })}>
      <div>
        <p style={{ marginBottom: 4 }}>
          {patient.name}
          {' '}
          {patient.middleName}
          {''}
          {patient.lastname}
        </p>
        <ProgressBar value={patient.matchPercentage} />
      </div>
      <IconButton
        aria-label="show more"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        size="small"
      >
        <MoreIcon />
      </IconButton>
      {renderMenu}
    </div>
  );
}

export default ColItem;
