/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable guard-for-in */
import IS_BROWSER from 'utils/isBrowser';

import Logger from './Logger';

interface IstorageAsyncRespone {
  error: boolean;
  message?: string,
  data?: any,
}

interface IStorageApi {
  set: (key: string, data: any) => Promise<IstorageAsyncRespone>,
  put: (key: string, data: any) => Promise<IstorageAsyncRespone>,
  get: (key: string) => Promise<IstorageAsyncRespone>,
  remove: (key: string) => Promise<IstorageAsyncRespone>,
  setSync: (key: string, data: any) => void,
  putSync: (key: string, data: any) => void,
  getSync: (key: string) => any,
  removeSync: (key: string) => void,
  clearSync: () => void,
  removeItem: (key: string) => void,
}

const fakeStorage = (function () {
  let storage = new Map();
  return {
    getItem: (key: string) => storage.get(key),
    setItem: (key: string, value: any) => storage.set(key, value),
    removeItem: (key: string) => storage.delete(key),
    clear: () => storage = new Map(),
  };
}());

class Storage {
  private storage: globalThis.Storage | typeof fakeStorage;

  constructor(type: string) {
    this.storage = IS_BROWSER
      ? type === 'session' ? window.sessionStorage : window.localStorage
      : fakeStorage;
  }
  static existMessage = 'there already exists data in storage with this key: %c__key__';

  static notFoundMessage = 'there is not found any existing data in storage with this key: %c__key__';

  static printMessageStyle = 'font-weight: bold; font-size: 12px';

  static notAllowedTypes = ['function', 'symbol', 'bigint'];

  static replacer = (key: string | number, value: any) => {
    const typeOfValue = typeof value;
    if (Storage.notAllowedTypes.includes(typeOfValue)) {
      Logger.info(`key: ${key} is type of ${typeOfValue}, such values are being either omitted or changed to null `);
    }
    return value;
  }

  public set = async (key: string, data: any): Promise<IstorageAsyncRespone> => {
    const storageKey = key;
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.storage.getItem(storageKey)) {
          const message = Storage.existMessage.replace('__key__', key);
          Logger.info(message, Storage.printMessageStyle);
          return resolve({
            error: true,
            message,
          });
        }
        this.storage.setItem(storageKey, Storage.handleData(data));
        resolve({
          error: false,
          message: '',
        });
      }, 0);
    });
  }

  public put = async (key: string, data: any): Promise<IstorageAsyncRespone> => {
    const storageKey = key;
    return new Promise(resolve => {
      setTimeout(() => {
        if (!this.storage.getItem(storageKey)) {
          const message = Storage.notFoundMessage.replace('__key__', key);
          Logger.info(message, Storage.printMessageStyle);
          return resolve({
            error: true,
            message,
          });
        }
        this.storage.setItem(storageKey, Storage.handleData(data));
        resolve({
          error: false,
          message: '',
        });
      }, 0);
    });
  }

  public get = async (key: string): Promise<IstorageAsyncRespone> => {
    const storageKey = key;
    return new Promise(resolve => {
      setTimeout(() => {
        const returnValue = this.storage.getItem(storageKey);
        if (returnValue) {
          return resolve({ error: false, message: '', data: JSON.parse(returnValue) });
        }
        const message = Storage.notFoundMessage.replace('__key__', key);
        Logger.info(message, Storage.printMessageStyle);
        return resolve({ error: true, message, data: null });
      }, 0);
    });
  }

  public remove = async (key: string): Promise<IstorageAsyncRespone> => {
    const storageKey = key;
    return new Promise(resolve => {
      setTimeout(() => {
        this.storage.removeItem(storageKey);
        return resolve({
          error: false,
          message: '',
        });
      }, 0);
    });
  }

  public clear = async () => new Promise(resolve => {
    setTimeout(() => {
      this.storage.clear();
      return resolve({
        error: false,
        message: '',
      });
    }, 0);
  });

  public setSync = (key: string, data: any) => {
    const storageKey = key;
    if (this.storage.getItem(storageKey)) {
      const message = Storage.existMessage.replace('__key__', key);
      Logger.info(message, Storage.printMessageStyle);
      return;
    }
    this.storage.setItem(storageKey, Storage.handleData(data));
  }

  public putSync = (key: string, data: any) => {
    const storageKey = key;
    if (!this.storage.getItem(storageKey)) {
      const message = Storage.notFoundMessage.replace('__key__', key);
      Logger.info(message, Storage.printMessageStyle);
    }
    this.storage.setItem(storageKey, Storage.handleData(data));
  }

  public getSync = (key: string) => {
    const storageKey = key;
    const returnValue = this.storage.getItem(storageKey);
    if (!returnValue) {
      const message = Storage.notFoundMessage.replace('__key__', key);
      Logger.info(message, Storage.printMessageStyle);
      return;
    }

    return JSON.parse(returnValue);
  }

  public removeSync = (key: string) => {
    const storageKey = key;
    this.storage.removeItem(storageKey);
  }

  public clearSync = () => {
    this.storage.clear();
  };

  static handleData(data: any) {
    return JSON.stringify(data, Storage.replacer);
  }

  public removeItem = (key: string) => {
    this.storage.removeItem(key);
  }
}

export const sessionStorageApi: IStorageApi = new Storage('session');
export const localStorageApi: IStorageApi = new Storage('local');
