const {
  REACT_APP_AMAROS_URL,
} = process.env;

export const baseURL = REACT_APP_AMAROS_URL;

export const LOGIN = `${baseURL}/auth/login`;
export const REGISTER = `${baseURL}/auth/register`;
export const AUTH_ME = `${baseURL}/auth/me`;
export const GET_PATIENTS = `${baseURL}/patients`;
export const GET_DISEASES = `${baseURL}/diseases`;
export const GENERATE_CSV = `${baseURL}/search/csv`;
export const GET_PATIENT_IDS = `${baseURL}/patients/ids`;

export const GET_TRIALS = `${baseURL}/trials`;
export const CREATE_TRIAL = `${baseURL}/trials`;
export const GET_SITES = `${baseURL}/site`;

export const GET_UNITS = `${baseURL}/search/units`;
export const SEARCH_PATIENTS = `${baseURL}/search/patients`;
export const GET_PATIENT = `${baseURL}/patients`;
export const GET_VISITS = `${baseURL}/patients`;
