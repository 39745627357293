import { getToken, clearToken } from 'utils/tokenControls';
import { ISearchCriterias } from './types';
/* eslint-disable no-param-reassign */
import {
  // createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

// import Fetch from 'services/Fetch';
import { IAppState, IPatientsData, IUserData, IUserInfo, ITrialsData } from './types';

const initialState: IAppState = {
  user: null,
  token: '',
  isLoggedIn: false,
  rememberUser: false,
  patients: {
    data: [],
    meta: {
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      totalCount: 0,
      page: 0,
      pageCount: 0,
      take: 0,
    },
  },
  trials: {
    meta: {
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      totalCount: 0,
      page: 0,
      pageCount: 0,
      take: 0,
    },
    data: [],
  },
  searchCriteria: {},
};

const stateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setIsLoggedIn(state, { payload }: PayloadAction<IUserData>) {
      state.isLoggedIn = true;
      state.user = {
        user: payload.user,
        token: payload.token,
      }
    },
    setUser(state, { payload }: PayloadAction<{ user: IUserInfo }>) {
      state.isLoggedIn = true;
      state.user = {
        user: payload.user,
        token: getToken(),
      };
    },
    setPatients(state, { payload }: PayloadAction<IPatientsData>) {
      state.patients.data = payload.data;
      state.patients.meta = payload.meta;
    },
    setTrials(state, { payload }: PayloadAction<ITrialsData>) {
      state.trials.data = payload.data;
      state.trials.meta = payload.meta;
    },
    setSearchCrytieria(state, { payload }: PayloadAction<ISearchCriterias>) {
      state.searchCriteria = payload.data;
    },
    setIsLoggedOut(state) {
      state.isLoggedIn = false;
    },
    setIsNotAuthorized(state) {
      state.isLoggedIn = false;
      state.user = null;
      clearToken();
    }
  },
});

export default stateSlice;