import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'components/Button';

import { IUnitConditionItem } from './types';
import { unitCunjuncitonItemStyles } from './style';

function Item({ defaultValue, options = [''], onChange, enableDelete = false, removeItem, id }: IUnitConditionItem) {
  const {
    root,
  } = unitCunjuncitonItemStyles();
  return (
    <div className={root}>
      <Autocomplete
        defaultValue={defaultValue}
        freeSolo
        options={options as string[]}
        getOptionLabel={option => option || ''}
        onChange={(event, value, reson, details) => onChange(event, value, reson, details, id)}
        // className={autoComplete}
        // classes={{ root: autoComplete }}
        // @ts-ignore
        renderInput={params => (
          <TextField
            // defaultValue={defaultValue}
            {
            ...params
            }
            inputProps={{
              ...params.inputProps,
            }}
            margin="none"
            size="small"
            variant="outlined"
            style={{ minWidth: 200 }}
          />
        )
        }
      />
      {enableDelete && (
        <Button
          size="small"
          onClick={() => removeItem(id)}
        >
          <DeleteIcon color="error" />
        </Button>
      )}
    </div>
  );
}

export default Item;
