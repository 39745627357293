import { useEffect, useRef } from 'react';
import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

import CircularProgress from '@mui/material/CircularProgress';

import Row from './ColItem';
import { useColmnStyles } from './styles';

export default function Column(
  {
    header,
    patients,
    id,
    patientsMetaData,
    loading,
    onScrollFetch,
  }: {
    header: string;
    patients: any[];
    id: string | number;
    patientsMetaData: any;
    loading: boolean;
    onScrollFetch: () => void,
  }) {
  const classes = useColmnStyles();
  const invisibleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (invisibleRef.current) {
      const el = invisibleRef.current;
      let options = {
        rootMargin: '0px',
        threshold: 1.0
      }
      const callback = (records: IntersectionObserverEntry[]) => {
        records.forEach((record: IntersectionObserverEntry) => {
          if (record.intersectionRatio !== 1) { return; }
          onScrollFetch();
        });

      }
      let observer = new IntersectionObserver(callback, options);
      observer.observe(el);
      return () => observer.unobserve(el as HTMLDivElement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invisibleRef.current, onScrollFetch]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span>{header}</span>
        <span className={classes.counter}>
          {loading && <CircularProgress size={20} />}
          {!loading && (patientsMetaData?.itemCount || 0)}
        </span>
      </div>
      <Droppable droppableId={`droppable-${id}`} type="row">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classes.colDroppableZone}
            {...provided.droppableProps}
          >
            {patients.map((patient, index, self) => {
              const pointToInsertObservableDiv = Math.min(self.length, 20);
              const idx = self.length - pointToInsertObservableDiv;
              const evenIndex = idx - idx % 2;
              return (
                <>
                  {index === evenIndex && <div ref={invisibleRef} />}
                  <Draggable draggableId={`${patient.name}-${patient.id}-${header}`} index={index} key={`${patient.name}-${patient.id}-${header}`}>
                    {(provided, snapshot) => (
                      <div
                        // className={classes.draggableCol}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                        className={snapshot.isDragging ? classes.draggableItem : ''}
                      >
                        {/* <div className={classes.patientItem}>{patient.name}</div> */}
                        <Row patient={patient} />
                      </div>
                    )}
                  </Draggable>
                </>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div >
  );
}
