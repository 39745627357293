import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { getRangeColor } from './utils';
import useStyles from './styles';

function ProgressBar({ value }: { value: number }) {
  const classes = useStyles();
  const color = getRangeColor(Number(value));
  return (
    <div className={classes.root}>
      <LinearProgress
        classes={{
          root: classes.progressRoot,
          bar: `${classes.progressBar} ${classes[color]}`,
          colorPrimary: classes.colorPrimary,
        }}
        variant="determinate"
        value={value}
      />
      <Typography variant="body2" color="textSecondary">
        {value}
        %  Match
      </Typography>
    </div>
  );
}

export default ProgressBar;
