import Cookies from 'js-cookie'

import { sessionStorageApi, localStorageApi } from 'services/Storage';
import { USER_TOKEN } from 'configs/constants';
import Logger from 'services/Logger';

const setToken = (token: string, inLocal?: boolean): boolean => {
  if (!token) return false;

  if (inLocal) {
    localStorageApi.setSync(USER_TOKEN, token);
  } else {
    sessionStorageApi.setSync(USER_TOKEN, token);
  }
  Cookies.set(USER_TOKEN, token);

  return true;
};

export const clearToken = (): boolean => {
  try {
    sessionStorageApi.removeItem(USER_TOKEN);
    localStorageApi.removeItem(USER_TOKEN);
    Cookies.remove(USER_TOKEN);
    return true
  } catch (err) {
    Logger.error(err as string);
    return false;
  }
};

const getToken = (): string => {
  return Cookies.get(USER_TOKEN) || sessionStorageApi.getSync(USER_TOKEN) || localStorageApi.getSync(USER_TOKEN) as string || '';
};

export {
  getToken,
  setToken,
};
