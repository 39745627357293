import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';

import Layout from 'layouts';
import Detection from 'pages/Detection';
import Client from 'pages/Client';
import Patients from 'pages/Patients';
import Search from 'pages/Search';
import Trials from 'pages/Trials';
import CreateTrials from 'pages/Trials/CreateTrials';
import TrialList from 'pages/Trials/TrialsList';
import TrialDetails from 'pages/TrialDetails';

import { IRoutItem } from "./types";

const mainPaths: IRoutItem[] = [
  {
    path: '/',
    title: 'Main Page',
    key: 1,
    component: Layout,
  },
];

const appPaths: IRoutItem[] = [
  {
    path: '/trial/:id',
    title: 'Trial details',
    key: 6,
    component: TrialDetails,
    hidden: true,
    exact: true,
  },
  {
    path: '/trials',
    title: 'Trials',
    key: 1,
    icon: MapOutlinedIcon,
    component: Trials,
    subRoutes: [
      {
        path: '/create',
        title: 'Add trial',
        key: 2,
        component: CreateTrials,
      },
      {
        path: '',
        title: 'Trials',
        key: 1,
        component: TrialList,
      }
    ],
  },

  {
    path: '/search',
    title: 'Search',
    key: 2,
    component: Search,
    icon: SearchIcon,
  },
  {
    path: '/subjects/:clientId',
    title: 'Subject',
    key: 4,
    component: Client,
    icon: PeopleAltOutlinedIcon,
    hidden: true,
  },
  {
    path: '/subjects',
    title: 'Patients',
    key: 3,
    component: Patients,
    icon: PeopleAltOutlinedIcon,
  },
  {
    path: '/detection',
    title: 'Detection',
    key: 5,
    component: Detection,
    icon: BubbleChartOutlinedIcon,
  },
];

export {
  mainPaths,
  appPaths,
};
