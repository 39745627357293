import { useMemo, MouseEvent, useState, ChangeEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';


import { Row } from 'react-table';
const MultipleSelectCheckmarks = ({
  // Tdo fix typeing
  // @ts-ignore
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const options = useMemo(() => {
    const options: Set<string> = new Set();
    preFilteredRows?.forEach((row: Row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, checked },
    } = event;
    setFilter((prevState: string[]) => {

      if (checked) {
        return prevState ? [...prevState, value] : [value];
      } else {
        const newState = prevState.filter((filter: string) => filter !== value);
        return newState;
      }
    })
  };

  const resetAll = () => { setFilter([]) };

  const handleProfileMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'table_state_filter_menu';

  return (
    <FormControl sx={{ m: 0, ml: 'auto' }} onClick={e => e.stopPropagation()}>
      <IconButton
        size="small"
        edge="end"
        onClick={handleProfileMenuOpen}
      >
        <FilterAltOutlinedIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={resetAll}>
          <ListItemText primary='Uncheck all' />
        </MenuItem>
        {options.map((name) => (
          <MenuItem key={name}>
            <FormControlLabel
              sx={{ m: 0, width: '100%' }}
              control={
                <Checkbox value={name} onChange={handleChange} checked={filterValue?.indexOf(name) > -1} />
              }
              label={name || ''}
            />
          </MenuItem>

        ))}
      </Menu>
    </FormControl >
  );
};

export default MultipleSelectCheckmarks;

