import { withStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const AntTabs = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  indicator: {
    backgroundColor: theme.palette.primary.light,
  },
}))(Tabs);

export const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.light,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.primary.dark,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);
