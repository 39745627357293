import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  controlls: {
    width: 300,
    marginBottom: 20,
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gap: 20,
  },
  conjunctionWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 15,
  },
}));

export const useFormStyles = makeStyles((theme: any) => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  autoComplete: {
    marginTop: 0,
    marginBottom: 0,
    minWidth: 200,
  },
  formField: {
    minWidth: '200px !important',
  },
  accordionSummary: {
    justifyContent: 'space-between',
  }
}));

export const useUnitLinkStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
    '& > *': {
      margin: `${theme.spacing(1)}px 0`,
    }
  }
}))

export const unitGroupStyles = makeStyles((theme: any) => ({
  root: {
    border: '1px solid',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    width: '100%',
    minHeight: 54,
  },
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 36,

  },
  controlButtons: {
    display: 'grid',
    gap: 8,
    gridTemplateColumns: 'auto auto auto',
  },
  contentHolder: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginButtom: theme.spacing(1),
    }
  }
}))

export const unitCunjuncitonItemStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
