import { makeStyles } from '@mui/styles';

const drawerWidth = 260;

const useStyles = makeStyles((theme: any) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: '72px',
    height: 'calc(100vh - 72px)',
    position: 'absolute',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
  },
  sectionHeader: {
    padding: '16px 16px 0',
  },
  left16: {
    paddingLeft: 16,
  },
  buttonHolder: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: 16,
    minHeight: 36,
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdownWrapper: {
    padding: '8px 16px',
  },
}));

export default useStyles;
