import { useState, useCallback, useEffect } from 'react';

import Button from 'components/Button';

function Condition({ onClick, defaultState = 'and' }: { onClick: (name: string) => void, defaultState?: string }) {
  const [state, setState] = useState(defaultState === 'and' ? true : false);

  useEffect(() => {
    setState(defaultState === 'and' ? true : false);
  }, [defaultState]);

  const handleClick = useCallback(() => {
    setState(prev => {
      onClick(!prev ? 'and' : 'or');
      return !prev;
    });
  }, [onClick]);

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      size="small"
      color="primary">
      {state ? 'AND' : 'OR'}
    </Button>
  )
}

export default Condition;