import React, { useState, useCallback } from 'react';

import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import GridContainer from 'components/Grid/GridContainer';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import loadBullet from 'highcharts/modules/bullet.js';
import Paper from '@mui/material/Paper';
import GridItem from 'components/Grid/GridItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';

import Fetch from 'services/Fetch';
import { GET_PATIENT_IDS } from 'configs/api';
import useMount from 'hooks/useMount';
import dndImage from 'assets/images/dnd.png';
import detectionImg from 'assets/images/det.jpeg';
import detectionImg2 from 'assets/images/det2.jpeg';

import Drawer from './Drawer';
import Upload from './UploadButton';
import useStyles from './style';
import { glaucomaChart, retinaChart, amdGrade } from './chartOptions';

loadBullet(Highcharts);

const detectionImages = [detectionImg, detectionImg2];
// @ts-ignore
function TabPanel(props) {
  const classes = useStyles();
  const {
    children, value, index, title, showPrev, showNext, ...other
  } = props;

  return (
    <>
      {value === index ? (
        <Paper elevation={3}>
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={classes.tabPanel}
          >

            <div>
              <Typography variant="h5" color="textPrimary" className={classes.tabPanelHeader}>
                Step
                {' '}
                {value + 1}
                {':  '}

                {title}

              </Typography>
              <div className={classes.tabPanelContent}>
                {children}
              </div>
            </div>
            <Paper>
              <div className={classes.tabPanelFooter}>
                <Button variant="contained" color="primary" disabled={value === 0} onClick={showPrev}>Previous</Button>
                <Button variant="contained" color="primary" disabled={value === 1} onClick={showNext}>Next</Button>
                <Button variant="contained" color="primary" disabled>Save</Button>
                <Button variant="contained" color="primary">Cancel</Button>
              </div>
            </Paper>
          </div>
        </Paper>
      )
        : null}
    </>
  );
}

function Detection() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [patientIds, setPatientIds] = useState(() => ([]));
  const [showDetections, setShowDetections] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [currentImage, setCurrentImage] = useState(() => (detectionImages[0]));

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const getPatientsIds = useCallback(async () => {
    const results = await Fetch.get(GET_PATIENT_IDS);
    if (results.ids) {
      setPatientIds(results.ids);
    }
  }, []);

  useMount(() => {
    getPatientsIds();
  });

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const getDetection = useCallback(() => {
    patientId && setShowDetections(true);
  }, [patientId]);

  const resetFilters = useCallback(() => {
    setPatientIds([]);
    setShowDetections(false);
  }, []);

  const showNext = useCallback(() => {
    setTabValue(prev => {
      if (prev === 1) return prev;
      return prev + 1;
    });
  }, []);

  const showPrev = useCallback(() => {
    setTabValue(prev => {
      if (prev === 0) return prev;
      return prev - 1;
    });
  }, []);

  return (
    <div className={classes.root}>
      <Drawer
        open={open}
        handleDrawerClose={handleDrawerClose}
        data={patientIds}
        onApply={getDetection}
        resetFilters={resetFilters}
        patientId={patientId}
        selectPatientId={setPatientId}
      />
      <GridContainer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >

          <GridContainer justifyContent="space-between" style={{ marginBottom: 20 }}>
            <GridItem sm={2}>
              {!open && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={classes.menuButton}
                  size="large">
                  <MenuIcon />
                </IconButton>
              )}
            </GridItem>
          </GridContainer>
          {!showDetections && (
            <>
              <GridContainer justifyContent="center">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
                >
                  <Tab
                    label={(
                      <div style={{ display: 'flex' }}>
                        <Typography variant="h4">
                          <b>1</b>
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                          <Typography variant="subtitle1">
                            <b>Upload Images</b>
                          </Typography>
                          <Typography variant="caption" classes={{ root: classes.tabLabelCaption }}>
                            Upload Images
                          </Typography>
                        </div>
                      </div>
                    )}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                      textColorInherit: classes.textColorInherit,
                    }}
                  />
                  <Tab
                    label={(
                      <div style={{ display: 'flex' }}>
                        <Typography variant="h4" classes={{ root: classes.tabLabel }}>
                          <b>2</b>
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                          <Typography variant="subtitle1">
                            <b>Patient Details</b>
                          </Typography>
                          <Typography variant="caption" classes={{ root: classes.tabLabelCaption }}>
                            Fill Patient Details
                          </Typography>
                        </div>
                      </div>
                    )}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                      textColorInherit: classes.textColorInherit,
                    }}
                  />
                  <Tab
                    label={(
                      <div style={{ display: 'flex' }}>
                        <Typography variant="h4" classes={{ root: classes.tabLabel }}>
                          <b>3</b>
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                          <Typography variant="subtitle1">
                            <b>Medical Details</b>
                          </Typography>
                          <Typography variant="caption" classes={{ root: classes.tabLabelCaption }}>
                            Fill Medical Details
                          </Typography>
                        </div>
                      </div>
                    )}
                    disabled
                    classes={{
                      root: classes.tabRootDisabled,
                    }}
                  />
                </Tabs>
              </GridContainer>
              <GridContainer justifyContent="center">
                <TabPanel value={tabValue} index={0} title="Upload Images" showNext={showNext} showPrev={showPrev}>
                  <div style={{ height: 560 }}>
                    <div style={{ display: 'flex', marginTop: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                      {/* @ts-ignore */}
                      <Checkbox name="sendAlert" label="Send alert to all primary consultants" />

                      {/* @ts-ignore */}
                      <Checkbox name="urgent" label="Make this case as urgent" />
                      <Upload />
                      <Button variant="contained">Delete all images</Button>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <img src={dndImage} alt="drang and drop icon" />
                      <Typography variant="subtitle2">
                        You can drag and drop images files into this container
                      </Typography>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={1} title="Patient Details" showNext={showNext} showPrev={showPrev}>
                  <GridContainer justifyContent="center">
                    <GridItem className={classes.forms} lg={6} md={7}>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Given Names (First Name)" />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Family Names (Last Name)" />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem md={8}>
                          <FormControl className={classes.formControl} fullWidth>
                            <TextField variant="outlined" label="Select Site" />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <Button variant="contained">Site List</Button>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel classes={{ shrink: classes.formControlLabel }}>Gender</InputLabel>
                            <Select>
                              <MenuItem value="male">Male</MenuItem>
                              <MenuItem value="female">Female</MenuItem>
                              <MenuItem value="other">Other</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel classes={{ shrink: classes.formControlLabel }}>Ethnicity</InputLabel>
                            <Select>
                              <MenuItem value="a">African American/African Descent</MenuItem>
                              <MenuItem value="b">Latin American/Hispanic</MenuItem>
                              <MenuItem value="c">Caucasian/White</MenuItem>
                              <MenuItem value="d">Asian/Pacific Islander</MenuItem>
                              <MenuItem value="e">Indian subcontinent origin</MenuItem>
                              <MenuItem value="f">Native American/Alaskan Native</MenuItem>
                              <MenuItem value="g">Multi-Racial</MenuItem>
                              <MenuItem value="h">Decline To State</MenuItem>
                              <MenuItem value="i">Other</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Contact Number" />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Secondary Number" />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Mobile Number" />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <div>
                            Patient
                          </div>
                          <div style={{ marginLeft: 5 }}>
                            {/* @ts-ignore */}
                            <Checkbox name="sendAlert" label="consents" />
                            {/* @ts-ignore */}
                            <Checkbox name="urgent" label="does not consent" />
                          </div>
                          <div>
                            <Typography variant="caption">to data collection for research and</Typography>
                          </div>
                          <div>
                            <Typography variant="caption">development</Typography>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem className={classes.forms} lg={6} md={7}>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                            {/* @ts-ignore */}
                          {/* <KeyboardDatePicker
                              id="date-picker-dialog"
                              label="Date picker dialog"
                              format="MM/dd/yyyy"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              inputVariant="outlined"
                            />
                          </MuiPickersUtilsProvider> */}
                        </GridItem>
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Medical Record #" />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Alternate Identifier" />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Payers" />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <GridItem>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel classes={{ shrink: classes.formControlLabel }}>Send Report To Patient</InputLabel>
                            <Select>
                              <MenuItem value=""> --Select Report Type--</MenuItem>
                              <MenuItem value="notReq">Not Requested</MenuItem>
                              <MenuItem value="report">Only Report</MenuItem>
                              <MenuItem value="images">Report With Images</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl className={classes.formControl}>
                            <TextField variant="outlined" label="Email" type="email" />
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer justifyContent="space-between" alignItems="center">
                        <FormControl className={classes.formControl} fullWidth>
                          <TextField variant="outlined" multiline label="Postal Address" rows={3} />
                        </FormControl>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </TabPanel>
              </GridContainer>
            </>
          )}
          {showDetections && (
            <div>
              <Typography variant="subtitle1">

                Patient ID:
                {' '}
                {patientId}
              </Typography>
              <GridContainer>
                <GridItem md={12} lg={6}>
                  <Paper>
                    <div className={classes.columnChart}>
                      <HighchartsReact
                        options={{
                          ...amdGrade,
                        }}
                        highcharts={Highcharts}
                      />
                    </div>
                  </Paper>
                </GridItem>
                <GridItem md={12} lg={6}>
                  <div className={classes.detectionImage}>
                    <div className={classes.detectionImageWrapper}>
                      <img src={currentImage} alt="detection" />
                    </div>
                    <div>
                      <IconButton
                        onClick={() => setCurrentImage(detectionImages[0])}
                        aria-label="Previous Image"
                        size="large">
                        <ChevronLeftIcon fontSize="large" color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() => setCurrentImage(detectionImages[1])}
                        aria-label="Next Image"
                        size="large">
                        <ChevronRightIcon fontSize="large" color="primary" />
                      </IconButton>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={12} lg={6}>
                  <Paper>
                    <div className={classes.bulletChart}>
                      <HighchartsReact
                        options={{
                          ...retinaChart,
                        }}
                        highcharts={Highcharts}
                        containerProps={{
                          style: {
                            height: 150,
                          },
                        }}
                      />
                    </div>
                  </Paper>
                  <Paper>
                    <div className={classes.bulletChart}>
                      <HighchartsReact
                        options={{
                          ...glaucomaChart,
                        }}
                        highcharts={Highcharts}
                        containerProps={{
                          style: {
                            height: 150,
                          },
                        }}
                      />
                    </div>
                  </Paper>
                </GridItem>
              </GridContainer>
            </div>
          )}
        </main>
      </GridContainer>

    </div>
  );
}

export default Detection;
