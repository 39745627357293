import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'store/actions';
import Fetch from 'services/Fetch';
import { AUTH_ME } from 'configs/api';
import { getToken } from 'utils/tokenControls';

import Login from 'pages/Login';
import useMemoSelector from 'hooks/useMemoSelector';
import { getIsLoggedIn } from 'store/selectors';

import { IAuthorize } from './type';

function Authorize({ children }: IAuthorize) {
  const dispatch = useDispatch();

  const authMe = async () => {
    const result = await Fetch.get(AUTH_ME);
    if (result) {
      const { headers, message, statusCode, ...rest } = result;

      if (statusCode === 401) {
        dispatch(actions.setIsNotAuthorized());
        return;
      } else {
        dispatch(actions.setUser({ user: { ...rest } }));

      }
    }
  };

  useLayoutEffect(() => {
    getToken() && authMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoggedIn } = useMemoSelector(state => ({
    isLoggedIn: getIsLoggedIn(state),
  }));

  const content = getToken() || isLoggedIn ? children : <Login />;

  return content;
}

export default Authorize;
