// @ts-nocheck
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 55px)',
    minHeight: 'calc(100% - 55px)',
  },
  breadcrumbWrapper: {
    background: 'white',
    padding: 20,
  },
  breadCrumbItem: {
    height: 20,
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  activeBreadcrumb: {
    color: theme.palette.primary.main,
  }
}));


export const useTableStyles = makeStyles((theme: Theme) => ({
  sortIcons: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellContent: {
    display: 'flex',
  },
  cellContentHeader: {
    display: 'flex',
    minWidth: 'max-content',
  },
  cellButtons: {
    opacity: props => (props.isMobile || props.isIpad ? 1 : 0),
    transition: 'all 200ms ease-in',
    marginLeft: 'auto',
  },
  cellContentWrapper: {
    display: 'flex',
    minWidth: 'max-content',
    '&:hover': {
      '& $cellButtons': {
        opacity: 1,
      },
    },
  },
  tableCell: {
    '&:hover': {
      '& $cellButtons': {
        opacity: 1,
      },
    },
  }
}));

export default useStyles;
