// @ts-nocheck

export const diagnosisOptions = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    ignoreHiddenSeries: false,
  },
  exporting: {
    enabled: true,
  },
  title: {
    text: 'Diagnosed Diseases & Progression',
    useHTML: true,
    style: {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  tooltip: {
    borderRadius: 5,
    headerFormat: '<div style="font-size: 14rpx">{point.key}</div>',
    pointFormat: '<ul style="color:{series.color}"><li>{series.name}: <span>{point.y:.1f}</span></li></ul>',
    shared: true,
    useHTML: true,
  },
  xAxis: {
    crosshair: true,
    categories: [
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
    ],
    tickWidth: 1,
    credits: {
      enabled: false,
    },
    labels: {
      style: {
        fontSize: '14pxm',
        color: '#3c4043',
        fontWeight: '600',
      },
      formatter() {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.value;
      },
    },
    lineWidth: 1,
    gridLineDashStyle: 'Dot',
  },
  yAxis: {
    title: {
      text: 'Severity Level',
      style: {
        fontSize: '18px',
      },
    },
    labels: {
      style: {
        fontSize: '14px',
        color: '#3c4043',
        fontWeight: '600',
      },
    },
    gridLineDashStyle: 'Dot',
  },
  plotOptions: {
    column: {
      borderWidth: 1,
    },
    series: {
      borderRadius: 5,
      animation: {
        duration: 2500,
        easing: 'easeOutBounce',
      },
    },
  },
};

export const maculaOptions = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'line',
    ignoreHiddenSeries: false,
  },
  exporting: {
    enabled: true,
  },
  title: {
    text: 'Macula Thickness - Foveal',
    useHTML: true,
    style: {
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  colors: ['#006d75', '#9e3c3e'],
  tooltip: {
    borderRadius: 5,
    headerFormat: '<div style="font-size: 14rpx">{point.key}</div>',
    pointFormat: '<ul style="color:{series.color}"><li>{series.name}: <span>{point.y:.1f}</span></li></ul>',
    shared: true,
    useHTML: true,
  },
  legend: {
    enable: false,
  },
  xAxis: {
    crosshair: true,
    categories: [
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
    ],
    tickWidth: 1,
    labels: {
      style: {
        fontSize: '14pxm',
        color: '#3c4043',
        fontWeight: '600',
      },
      formatter() {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.value;
      },
    },
    lineWidth: 1,
    gridLineDashStyle: 'Dot',
  },
  yAxis: {
    title: '',
    labels: {
      style: {
        fontSize: '14px',
        color: '#3c4043',
        fontWeight: '600',
      },
    },
    gridLineDashStyle: 'Dot',
  },
  plotOptions: {
    column: {
      borderWidth: 1,
    },
    series: {
      borderRadius: 5,
      animation: {
        duration: 2500,
        easing: 'easeOutBounce',
      },
    },
  },
};

export const iopOptions = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'line',
    ignoreHiddenSeries: false,
  },
  exporting: {
    enabled: true,
  },
  title: {
    text: 'IOP',
    useHTML: true,
    style: {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  tooltip: {
    borderRadius: 5,
    headerFormat: '<div style="font-size: 14rpx">{point.key}</div>',
    pointFormat: '<ul style="color:{series.color}"><li>{series.name}: <span>{point.y:.1f}</span></li></ul>',
    shared: true,
    useHTML: true,
  },
  legend: {
    enable: false,
  },
  colors: ['#006d75', '#9e3c3e'],
  xAxis: {
    crosshair: true,
    categories: [
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
    ],
    tickWidth: 1,
    labels: {
      style: {
        fontSize: '14pxm',
        color: '#3c4043',
        fontWeight: '600',
      },
      formatter() {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.value;
      },
    },
    lineWidth: 1,
    gridLineDashStyle: 'Dot',
  },
  yAxis: {
    title: '',
    labels: {
      style: {
        fontSize: '14px',
        color: '#3c4043',
        fontWeight: '600',
      },
    },
    gridLineDashStyle: 'Dot',
  },
  plotOptions: {
    column: {
      borderWidth: 1,
    },
    series: {
      borderRadius: 5,
      animation: {
        duration: 2500,
        easing: 'easeOutBounce',
      },
    },
  },
};
