import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';

import { ISwitcher } from './types';

function Switcher({ required, checked, onChange, name, label, className, ...props }: ISwitcher) {
  return (
    <FormControl required={required} className={className}>
      <FormControlLabel
        control={(
          <Switch
            checked={checked}
            onChange={onChange}
            name={name}
            color={props.color}
          />
        )}
        label={label || ''}
      />
    </FormControl>
  )
}

export default Switcher;
