// @ts-nocheck
// Todo fix typings
import {
  useCallback,
  // useState,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import Drawer from '@mui/material/Drawer';
// import Box from '@mui/material/Box';

import Fetch from 'services/Fetch';
import { GENERATE_CSV } from 'configs/api';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/Button';
import useMemoSelector from 'hooks/useMemoSelector';
import { getPatients, getSearchCriteria } from 'store/selectors';
import { IPatientMetaData } from 'store/state/types';

import Table from './Tablee';
// import Filters from './Filters';
import useStyles from './style';

function Patients() {
  const classes = useStyles();
  const { patients, searchCriteria } = useMemoSelector(state => ({
    patients: getPatients(state),
    searchCriteria: getSearchCriteria(state),
  }));
  // const [drawerOpen, setDrawerOpen] = useState(false);
  const { data, meta } = patients;
  const { push } = useHistory();
  const selectedRowsRef = useRef([]);

  const handleOnRowClick = useCallback(({ id, matchPercentage }) => {
    push(`/subjects/${id}`, { patientId: id, matchPercentage });
  }, [push]);

  const handleSelectedRowsChange = useCallback(rows => {
    // !! important fix typing here
    // @ts-ignore
    const arrId = rows.map(({ id }) => id);
    selectedRowsRef.current = arrId;
  }, []);

  const handleGenerateCSV = useCallback(async () => {
    const reqBody = {
      units: searchCriteria.units,
      condition: searchCriteria.query,
    }
    const results = await Fetch.post(GENERATE_CSV, reqBody);
    // !! important fix typing here
    const csvContent = results.csv.map((e: any) => e.join(',')).join('\n');
    const zip = new JSZip();
    const folder = zip.folder('amaros');
    folder?.file('patients.csv', csvContent);
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'patients.zip');
    });
  }, [searchCriteria]);


  // const toggleDrawer = () => setDrawerOpen(prev => !prev);
  return (
    <div className={classes.root}>
      {/* <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Box sx={{ maxWidth: 300, minWidth: 300, width: 300 }}>
          <Filters />
        </Box>
      </Drawer> */}
      <GridContainer>
        <main
          className={clsx(classes.content)}
        >
          {/* <Button variant="outlined" onClick={toggleDrawer}>Fitlers</Button> */}
          <GridContainer justifyContent="space-between" className={classes.reportDataHeader}>
            {!!data?.length && (
              <>
                <GridItem>
                  <Typography gutterBottom variant="subtitle2" component="p" color="primary">
                    About this trial
                  </Typography>
                  <Typography gutterBottom variant="subtitle2" component="p" color="textSecondary">
                    {' '}
                    Total Practice Potential
                    {' '}
                    <b>{(meta as IPatientMetaData).itemCount}</b>
                    {' '}
                    |
                    Total Patients Considered for Trial
                    {' '}
                    <b>{(meta as IPatientMetaData).totalCount}</b>
                  </Typography>
                </GridItem>
                <GridItem>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateCSV}
                  >
                    GENERATE POTENTIALLY ELIGIBLE REPORT
                  </Button>
                </GridItem>
              </>
            )}
          </GridContainer>

          <Paper square>
            <Table
              data={data}
              handleOnRowClick={handleOnRowClick}
              onSelectionChange={handleSelectedRowsChange}
            />
          </Paper>
        </main>
      </GridContainer>
    </div>
  );
}

export default Patients;