// @ts-nocheck
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  clientSection: {
    display: 'grid',
    gap: props => (props.isIpad || props.isMobile ? 20 : 0),
    padding: 20,
    gridTemplateColumns: props => (props.isIpad || props.isMobile ? '' : '50% 50%'),
    gridTemplateRows: props => (props.isIpad || props.isMobile ? 'auto auto' : ''),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  referButtons: {
    margin: '0 5px',
  },
  progressBarWrapper: {
    margin: '0 10px',
  },
  goBack: {
    marginLeft: 24,
  },
  treatmentsTableHeder: {
    background: theme.palette.primary.light,
    backgroundImage: 'none',
    color: 'white',
  },
  treatmentsTableHederCell: {
    padding: '12px !important',
    border: 'none !important',
  },
  treatmentsTableCell: {
    padding: '12px !important',
  },
  nextPrevPatient: {
    [theme.breakpoints.between('sm', 'lg')]: {
      position: 'fixed',
      right: '20px',
      zIndex: 11111,
      bottom: '20px',
    },
  },
  selectionsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 10,
    paddingTop: 12,
    width: 280,
    // justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  subtitle1: {
    fontSize: '1.2rem !important',
    color: '#333333 !important',
  },
  subtitle2: {
    fontSize: '1.2rem !important',
    color: '#333333 !important',
    margin: 0,
  },
  tabPanel: {
    padding: 0,
  },
  chartsSelect: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: 'fit-content',
    'MuiInput-formControl': {
      marginTop: 0,
    },
  },
  chartWrapperRight: {
    width: '100%',
    paddingLeft: 10,
  },
  chartWrapperLeft: {
    width: '100%',
    paddingRight: 10,
  }
}));

export default useStyles;
