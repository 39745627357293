import React from 'react';

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { appPaths } from 'routes';
import { IRoutItem } from 'routes/types';

import Header from './Header';
import Body from './Body';

function Layout() {
  return (
    <>
      <Header />
      <Body>
        <Switch>
          {
            appPaths.map(({ path, key, component: Component, subRoutes, exact }: IRoutItem) => {
              if (!Component) return null;
              return (
                <Route
                  key={key}
                  path={path}
                  exact={exact}
                  // @ts-ignore
                  render={routeProps => (<Component {...routeProps} subRoutes={subRoutes} />)}
                />
              );
            })
          }
          {/* trials page */}
          <Redirect to={`${appPaths[1].path}`} />
        </Switch>
      </Body>
    </>
  );
}

export default Layout;
