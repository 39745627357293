import { ReactNode } from "react";

function Body({ children }: { children: ReactNode }) {
  return (
    <div style={{ background: '#f1f3f8', flex: 1 }}>
      {children}
    </div>
  );
}

export default Body;
