import Button from '@mui/material/Button';

import { IButton } from './types';

function CustomButton({ children, size = 'small', ...rest }: IButton) {
  return (
    <Button {...rest} size={size}>
      {children}
    </Button>
  );
}

export default CustomButton;
