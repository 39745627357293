// @ts-nocheck
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  controlls: {
    width: 300,
    marginBottom: 20,
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gap: 20,
  },
  conjunctionWrapper: {
    display: 'grid',
    gridTemplateColumns: props => (props.isMobile || props.isIpad ? '' : '1fr 1fr'),
    gridTemplateRows: props => (props.isMobile || props.isIpad ? 'auto' : ''),
    gap: 15,
  },
}));

export default useStyles;
