// @ts-nocheck
/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.modal,
    padding: 40,

  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      justifyContent: '',
    },
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    left: '15px',
    color: theme.palette.secondary.main,
    '&:hover': {
      boxShadow: theme.shadows[18],
    },
  },
  preview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    [theme.breakpoints.up('sm')]: {
      width: 600,
      height: 500,
    },
    [theme.breakpoints.up('md')]: {
      width: 700,
      height: 600,
    },
    width: 300,
    height: 300,
  },
  previewImage: {
    width: '100%',
    height: '100%',
  },
  previewIconLeft: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    left: 0,
    color: theme.palette.primary.main,
    fontSize: '4rem',
  },
  previewIconRight: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: '4rem',
  },
  noImages: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
}));

const useImageStyle = makeStyles(theme => ({
  root: {
    width: 200,
    height: 200,
    margin: 5,
    boxShadow: theme.shadows[4],

    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[18],
    },
  },
}));
const Image = ({ onClick, src }) => {
  const classes = useImageStyle();
  return (
    <div
      className={classes.root}
      onClick={onClick}
    >
      <img src={src} alt="eye" style={{ width: '100%' }} />
    </div>
  );
};

function Photos({ close, images = [] }) {
  const classes = useStyles();
  const [isPreview, setIsPreview] = useState(false);
  const [previewImageIdx, setPreviewImageInd] = useState(0);
  const openPreview = (e, index) => {
    e.stopPropagation();
    setIsPreview(true);
    setPreviewImageInd(index);
  };
  const nextImage = e => {
    e.stopPropagation();
    setPreviewImageInd(prev => {
      if (prev < images.length - 1) {
        return ++prev;
      }
      return prev;
    });
  };
  const prevImage = e => {
    e.stopPropagation();
    setPreviewImageInd(prev => {
      if (prev > 0) {
        return --prev;
      }
      return prev;
    });
  };

  const handelClose = () => {
    if (isPreview) {
      setIsPreview(false);
    } else {
      close();
    }
  };
  return (
    <div className={classes.root} onClick={handelClose}>
      {images.length === 0 && (
        <Typography variant="h4" className={classes.noImages}>
          There are no available images
        </Typography>
      )}
      {isPreview && (
        <div className={classes.preview}>
          <img src={images[previewImageIdx] || '/'} alt="eye" className={classes.previewImage} />
          <ChevronLeftIcon fontSize="large" onClick={prevImage} className={classes.previewIconLeft} />
          <ChevronRightIcon fontSize="large" onClick={nextImage} className={classes.previewIconRight} />
        </div>
      )}
      <div className={classes.wrapper}>
        {images.map((src, index) => (
          <Image src={src} onClick={e => openPreview(e, index)} />
        ))}
      </div>
    </div>
  );
}

export default Photos;
