import React from 'react';

import Grid from '@mui/material/Grid';

import { IGridContainer } from './types';

function GridContainer(props: IGridContainer) {
  const { children, ...rest } = props;
  return (
    <Grid container {...rest}>
      {children}
    </Grid>
  );
};

export default GridContainer;
