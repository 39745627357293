// @ts-nocheck
import {
  useMemo,
  // useEffect,
  // forwardRef,
  useCallback,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux';
import moment from 'moment';

import MaUTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import Pagination from '@mui/material/Pagination';
// import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
// import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

import {
  // usePagination,
  // useRowSelect,
  useSortBy,
  useTable,
  useFilters,
} from 'react-table';

import ProgressBar from 'components/ProgressBar';
import { getSearchCriteria, getPatients } from 'store/selectors';
import useMount from 'hooks/useMount';
import useMemoSelector from 'hooks/useMemoSelector';
import Fetch from 'services/Fetch';
import { SEARCH_PATIENTS } from 'configs/api';
// import { IPatient } from 'store/state/types';
import { actions } from 'store/actions';

// import TablePaginationActions from './Pagination';
import SelectFilter from './SelectFilter';
import SearchFilter from './SearchFilter';
import { useTableStyles } from './style';
import Dropdown from 'components/Dropdown';

const rowsStyle = {
  cursor: 'pointer',
  '&:hover': {
    bgcolor: 'grey.100'
  }
};

const cellStyle = {
  color: 'primary.light',
};

const headStyles = {
  bgcolor: 'grey.100',
  '&:hover': {
    bgcolor: 'grey.200',
  },
};

const EnhancedTable = () => {
  const classes = useTableStyles();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { searchCriteria, patients } = useMemoSelector(state => ({
    searchCriteria: getSearchCriteria(state),
    patients: getPatients(state),
  }));
  const [data, setData] = useState([]);

  const searchPatients = useCallback(async (reqBody) => {
    const results = await Fetch.post(SEARCH_PATIENTS, reqBody);
    if (results && !results.error) {
      const { meta, data } = results;
      setData(data);
      dispatch(actions.setPatients({ data, meta }));
    }
  }, [dispatch]);

  useMount(() => {
    const reqBody = {
      units: searchCriteria.units,
      condition: searchCriteria.query,
      page: 1,
      take: 10,
    };

    searchPatients(reqBody);
  });

  const fetchTrials = useCallback((e) => {
    const reqBody = {
      units: searchCriteria.units,
      condition: searchCriteria.query,
      page: 1,
      take: e.target.value,
    };
    searchPatients(reqBody);
  }, [searchPatients, searchCriteria]);

  const gotoPage = useCallback((page) => {
    const reqBody = {
      units: searchCriteria.units,
      condition: searchCriteria.query,
      page,
      take: patients.meta.take,
    };
    searchPatients(reqBody);
  }, [searchCriteria, searchPatients, patients]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Filter: SearchFilter,
        canFilter: true,
        minWidth: 200,
        Cell: ({ row: { original } }) => (<Typography variant="body2" color="textSecondary" >
          {original.name}
          {' '}
          {original.middleName}
          {' '}
          {original.lastName}
        </Typography>)
      },
      { Header: 'Site', accessor: 'site' },
      {
        Header: 'Gender', accessor: 'gender',
        Cell: ({ row: { original } }) => (
          <Typography variant="body2" color="textSecondary" >
            {original.gender === 'F' ? 'Female' : 'Male'}
          </Typography>
        ),
      },
      {
        Header: 'Match %',
        accessor: 'matchPercentage',
        Cell: ({ row: { original } }) => (
          <>
            <ProgressBar value={original.matchPercentage} />
          </>
        ),
      },
      {
        Header: 'Last Visit',
        accessor: 'lastVisit',
        Filter: SearchFilter,
        // !!! Imporant check with David
        // @ts-ignore
        Cell: ({ row: { original } }) => moment(original.lastVisit).format('ll'),
      },
      {
        Header: 'Diagnosis',
        accessor: 'diagnoseDate',
        // !!! Imporant check with David
        // @ts-ignore
        render: ({ row: { original } }) => moment(original.diagnoseDate).format('ll'),

      },
      {
        Header: 'Status',
        accessor: 'status',
        // !!! Imporant check with David
        // @ts-ignore
        render: ({ row: { original } }) => moment(original.diagnoseDate).format('ll'),

      },
    ],
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: SelectFilter
    }),
    []
  );

  const defaultFilters = useMemo(() => ([{ id: 'status', value: [] }]), []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { filters: defaultFilters },
    },
    useFilters,
    useSortBy,
  );

  const goToPatient = (row) => {
    push(`/subjects/${row.original.id}`, { patientId: row.original.id, matchPercentage: row.original.matchPercentage });
  }


  return (
    <TableContainer sx={{ bgcolor: 'white', boxShadow: 3, }}>
      {/* <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        deleteUserHandler={deleteUserHandler}
        addUserHandler={addUserHandler}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      /> */}
      <MaUTable {...getTableProps()}>
        <TableHead sx={headStyles}>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...(column.getHeaderProps(column.getSortByToggleProps()))}>
                  <div className={classes.cellContent}>
                    <div className={classes.cellContentHeader}>
                      {column.render('Header')}
                      <span className={classes.sortIcons}>
                        {column.isSorted ?
                          column.isSortedDesc
                            ? <KeyboardArrowUpIcon fontSize="small" color="primary" />
                            : <KeyboardArrowDownIcon fontSize="small" color="primary" />
                          : null
                        }
                      </span>
                    </div>
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return <TableRow {...row.getRowProps()} sx={rowsStyle} onClick={() => goToPatient(row)}>
              {row.cells.map(cell => {
                return (
                  <TableCell {...cell.getCellProps()} sx={cellStyle} className={classes.tableCell}>
                    <div className={classes.cellContentWrapper}>
                      <Typography color="inherit">
                        {cell.render('Cell')}
                      </Typography>
                      <div className={classes.cellButtons}>{cell.column.actions?.()}</div>
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <Dropdown
                label="Rows per page"
                value={patients.meta.take}
                onChange={fetchTrials}
                options={[{ value: 5, label: 5 }, { value: 10, label: 10 }, { value: 20, label: 20 }]}
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align="right">
              <Pagination
                count={patients.meta.pageCount}
                showFirstButton
                showLastButton
                shape="rounded"
                onChange={(e, page) => gotoPage(page)}
                sx={{
                  display: 'inline-block',
                  '&> ul': {
                    flexWrap: 'nowrap'
                  },
                }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
  )
}

export default EnhancedTable;
