// @ts-nocheck
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  controlls: {
    display: 'flex',
    alignItems: 'baseline',
    width: props => (props.isMobile || props.isIpad ? 'auto' : 400),
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 20,
    gap: 10,
  },
  accardionDetails: {
    display: 'block'
  },
  accardionSummary: {
    background: theme.palette.primary.main,
    borderRadius: '4px 4px 0 0',
    color: 'white',
  },
  accardionSummaryExpandButton: {
    // color: 'white',
  },
  conjunctionsWrapper: {
    display: props => (props.isMobile || props.isIpad ? 'grid' : 'flex'),
    // flexWrap: 'wrap',
    gridTemplateRows: 'auto',
    gap: 10,
  }
}));

export default useStyles;
