// @ts-nocheck
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  root: {
    borderRadius: 0,
  },
  tableHeader: {
    background: theme.palette.primary.light,
    '& th': {
      color: 'white',
    },
  },
  tableRow: {
    '&:hover': {
      background: theme.palette.grey[200],
    },
  },
}));

function ProgressionTable({ rows }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} classes={{ root: classes.root }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Disease</TableCell>
            <TableCell>12 months</TableCell>
            <TableCell>24 months</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name} className={classes.tableRow}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.calories}</TableCell>
              <TableCell>{row.fat}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProgressionTable;
