import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { isProd } from 'configs/constants';

import state from './state';

const reducer = combineReducers({
  appState: state.reducer,
});

const store = configureStore({
  reducer,
  devTools: !isProd,
  middleware: getDefaultMiddleware({
    // TODO ::: need to remove all not serializable and immutable objects from state
    serializableCheck: false,
    immutableCheck: false,
  }),
});
export default store;
