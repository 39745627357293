import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import {
  IUnitLinks,
  IUnitConjunctionItem,
} from './types';
import { useUnitLinkStyles } from './style';
import ConditionGroup from './UnitConditionGroup';

function UnitGroups({
  data, addItem, removeItem, conjunctions, changeGroupOperator, include = false, exclude = false, chooseFromAutoSuggestion,
}: IUnitLinks) {
  const classes = useUnitLinkStyles();

  return (
    <Card style={{ margin: '0', width: '100%' }}>
      <Typography variant="body1" color="primary" style={{ padding: 16 }}>
        {include && 'Include'}
        {exclude && 'Exclude'}
      </Typography>
      <CardContent>
        <div className={classes.wrapper}>
          <ConditionGroup
            unitNames={data}
            data={conjunctions.children as IUnitConjunctionItem}
            conjunction={conjunctions.conjunction as string}
            addItem={addItem}
            id={conjunctions.id as string}
            removeItem={removeItem}
            chooseFromAutoSuggestion={chooseFromAutoSuggestion}
            changeGroupOperator={changeGroupOperator}
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default UnitGroups;
