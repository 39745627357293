/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useState, useEffect, useCallback, useLayoutEffect,
} from 'react';

import { useDispatch } from 'react-redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Button from 'components/Button';
import Input from 'components/Input';
import { GridContainer } from 'components/Grid';
import Checkbox from 'components/Checkbox';

import { actions } from 'store/actions';
import Fetch from 'services/Fetch';
import { LOGIN } from 'configs/api';
import { setToken } from 'utils/tokenControls';
import logo from 'assets/images/logo.jpg';

import useInputChange from './utils';
import useStyles from './styles';

function Login() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userName = useInputChange('');
  const password = useInputChange('');

  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin = useCallback(async () => {
    if (userName.value && password.value) {
      setIsLoading(true);
      const result = await Fetch.post(LOGIN, {
        email: userName.value,
        password: password.value,
      })
      if (result.user) {
        const {
          user,
          token: {
            accessToken
          },
        } = result;
        setToken(accessToken, rememberMe) //if true will save in local storage;
        dispatch(actions.setIsLoggedIn({ user: user, token: accessToken }));
      }
      setIsLoading(false);
    }
  }, [userName.value, password.value, rememberMe, dispatch]);

  const handleKeyDown = useCallback(e => {
    // keyCode 13 is Enter
    if (e.keyCode === 13) {
      handleLogin();
    }
  }, [handleLogin]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <GridContainer
      className={classes.loginPage}
      align="middle"
      justify="center"
      justifyContent="center"
      alignItems="center"
    >
      <div className={classes.formWrapper}>
        <GridContainer className={classes.formContent}>
          <GridContainer>
            <div className={classes.gridStyles}>
              <img src={logo} alt="amaros logo" className={classes.logo} />
            </div>
          </GridContainer>
          <GridContainer>
            <div className={classes.gridStyles}>
              <div className={classes.inputWrapper}>
                <Input
                  fullWidth
                  label="Username"
                  disabled={isLoading}
                  {...userName}
                />
              </div>
            </div>
            <div className={classes.gridStyles}>
              <div className={classes.inputWrapper}>
                <Input
                  fullWidth
                  disabled={isLoading}
                  label="Password"
                  type="password"
                  {...password}
                />
              </div>
            </div>
            <div className={classes.rememberMe}>
              <Checkbox disabled={isLoading} checked={rememberMe} onChange={() => setRememberMe(prev => !prev)} label="Remember me" />
            </div>
          </GridContainer>
          <GridContainer>
            <div>
              <Button
                size="small"
                color="primary"
                disabled={isLoading}
                className={classes.forgotButton}
              >Don't have an account
              </Button>
              <Button
                size="small"
                color="primary"
                disabled={isLoading}
                className={classes.forgotButton}
              >Forgot username or password
              </Button>
            </div>
          </GridContainer>
        </GridContainer>

        <div role="button" className={classes.loginButton} onClick={handleLogin}>
          <ChevronRightIcon />
        </div>
      </div>
    </GridContainer>
  );
}

export default Login;