import { useCallback, SyntheticEvent, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';

import { IUnitCodeOptions, IUnitSingleProp } from 'utils/types';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import Switch from 'components/Switch';

import Button from 'components/Button';
import { useFormStyles } from './style';
import { IForm, IFormElement, formOnChange, autoSuggestoinSelect } from './types';
// keep consistant with backend
import { unitFormElementsNames } from './utils';

const DropdownWrapper = ({ data, onChange }: IFormElement) => {
  const { formField } = useFormStyles();

  return (
    <Dropdown
      variant="outlined"
      className={formField}
      labelId={data.name}
      id={data.name}
      showEmpty
      options={(data.values as string[]).map(string => ({ value: string, label: string }))}
      name={data.name}
      label={unitFormElementsNames[data.name] || data.name}
      value={data.value as string}
      onChange={onChange}
      fullWidth={false}
    />
  );
};

const SearchableInput = ({ data, onChange, chooseFromAutoSuggestion }: IFormElement) => {
  const { autoComplete } = useFormStyles();
  const defaultValue = data.value ? data.options?.find(option => option.id === data.value) : null;
  return (
    <Autocomplete
      freeSolo
      defaultValue={defaultValue}
      options={data.options as IUnitCodeOptions[]}
      //@ts-ignore
      getOptionLabel={option => `${option.description || option.name}-(${option.code})`}
      // getOptionSelected={option => option.id}
      className={autoComplete}
      onChange={chooseFromAutoSuggestion}
      data-name={data.name}
      classes={{ root: autoComplete }}
      sx={{ margin: 0 }}

      renderInput={params => (
        <TextField
          {
          ...params
          }
          size="small"
          inputProps={{
            ...params.inputProps,
            'data-search': data.searchPath,
            name: data.name,
          }}
          onChange={onChange}
          label={unitFormElementsNames[data.name] || data.name}
          margin="normal"
          variant="outlined"
          sx={{ margin: 0 }}
        />
      )
      }
    />)
};

const InputEl = ({ data, onChange }: IFormElement) => {
  const { formField } = useFormStyles();

  return (
    <Input
      label={unitFormElementsNames[data.name] || data.name}
      className={formField}
      InputProps={{
        name: data.name,
        type: data.type === 'int' ? 'number' : 'string',
        value: data.value,
        onChange,
      }}
    />
  );
};

const Checkbox = ({ data, onChange }: IFormElement) => {
  const { formField } = useFormStyles();

  return (
    <Switch
      checked={data.checked}
      className={formField}
      onChange={onChange}
      name={data.name}
      color="primary"
      label={unitFormElementsNames[data.name] || data.name}
    />
  );
};

const DatePicker = ({ data, onChange }: IFormElement) => {
  const { formField } = useFormStyles();

  return (
    <TextField
      type="date"
      className={formField}
      name={data.name}
      label={unitFormElementsNames[data.name] || data.name}
      onChange={onChange}
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

const getFieldElement = (
  [fieldName, fieldData]: [string, IUnitSingleProp],
  index: number,
  $: any[],
  onChangeHandler: formOnChange,
  id: string,
  chooseFromAutoSuggestion: autoSuggestoinSelect,
  subFieldName?: string,
): any => {
  const data = { name: fieldName, ...fieldData };
  const valueType = fieldData.type;
  if (!valueType) return Object.entries(fieldData).map((data, index, arr) => getFieldElement(data as [string, IUnitSingleProp], index, arr, onChangeHandler, id, chooseFromAutoSuggestion, fieldName));
  if (valueType === 'enum') return <div key={`${index}${id}`}><DropdownWrapper key={`${index}${id}`} data={data} onChange={(e) => onChangeHandler(e, subFieldName)} /></div>;
  if (valueType === 'text') return <div key={`${index}${id}`}><SearchableInput key={`${index}${id}`} data={data} onChange={(e) => onChangeHandler(e, subFieldName)} chooseFromAutoSuggestion={chooseFromAutoSuggestion} /></div>;
  if (valueType === 'int') return <div key={`${index}${id}`}><InputEl key={`${index}${id}`} data={data} onChange={(e) => onChangeHandler(e, subFieldName)} /></div>;
  if (valueType === 'float') return <div key={`${index}${id}`}><InputEl key={`${index}${id}`} data={data} onChange={(e) => onChangeHandler(e, subFieldName)} /></div>;
  if (valueType === 'bool') return <div key={`${index}${id}`}><Checkbox key={`${index}${id}`} data={data} onChange={(e) => onChangeHandler(e, subFieldName)} /></div>;
  if (valueType === 'date') return <div key={`${index}${id}`}><DatePicker key={`${index}${id}`} data={data} onChange={(e) => onChangeHandler(e, subFieldName)} /></div>;
};

function Form({ data, name, id, onChangeHandler, chooseFromAutoSuggestion, removeUnit }: IForm) {
  const classes = useFormStyles();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const onChangeHandlerWrapper: formOnChange = useCallback((e, subUnitName?: string) => {
    onChangeHandler(e, id, subUnitName);
  }, [onChangeHandler, id]);

  const chooseFromAutoSuggestionHandler: autoSuggestoinSelect = useCallback((event, value, reson, details) => {
    chooseFromAutoSuggestion(event, value, reson, details, id);
  }, [chooseFromAutoSuggestion, id]);
  const fieilds = Object
    .entries(data)
    .map((...rest) => getFieldElement(...rest, onChangeHandlerWrapper, id, chooseFromAutoSuggestionHandler));

  return (
    <Card style={{ margin: '10px 0' }} >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${id}-content`}
          id={`panel${id}-header`}
          sx={{ margin: 0, height: 30, display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant={isMobile || isIpad ? "body2" : "body1"} color="primary" style={{ padding: 16 }}>
            {id}
            {!(isMobile || isIpad) && `| ${name}`}
          </Typography>
          <Button
            onClick={(e: SyntheticEvent) => removeUnit(e, id)}
          >
            <DeleteIcon color="error" />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <CardContent sx={{ margin: 0, padding: 0 }}>
            <form className={classes.form}>
              {fieilds}
            </form>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>

  );
}

export default Form;
