import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { IDropdown } from './types';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 150,
    maxWidth: 200,
  },
}));

function SimpleSelect({
  value,
  onChange,
  label,
  id,
  selectId,
  options = [],
  name,
  helperText,
  fullWidth = true,
  variant,
  disabled,
  required,
  showEmpty,
  className,
  size = 'small',
}: IDropdown) {
  const classes = useStyles();
  return (
    <FormControl size={size} className={clsx(classes.formControl, className)} fullWidth={fullWidth} variant={variant} required={required}>
      <InputLabel id={id || label}>{label}</InputLabel>
      <Select
        labelId={id || label}
        label={id || label}
        id={selectId}
        value={value}
        name={name}
        // @ts-ignore
        onChange={onChange}
        disabled={disabled}
        size={size}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {showEmpty && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options.map(({ value: v, label: l }) => (
          <MenuItem key={v} value={v}>{l}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default SimpleSelect;
