// @ts-nocheck

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 55px)',
    minHeight: 'calc(100% - 55px)',
  },
  breadcrumbWrapper: {
    background: 'white',
    padding: 20,
  },
  link: {

  },
  contentWrapper: {
    padding: 20,
    flex: 1,
  },
  contentHeader: {
    display: props => (props.isMobile ? 'grid' : 'flex'),
    gap: props => (props.isMobile ? 10 : 0),
    justifyContent: 'space-between',
  },
  contentHeaderButtonGroup: {
  },
  trialsTable: {
    marginTop: 20,
  },
}));


export const useTableStyles = makeStyles((theme: Theme) => ({
  sortIcons: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellContent: {
    display: 'flex',
  },
  cellContentHeader: {
    display: 'flex',
    minWidth: 'max-content',
  },
  cellButtons: {
    // @ts-ignore
    opacity: props => props.isMobile || props.isIpad ? 1 : 0,
    transition: 'all 200ms ease-in',
    marginLeft: 'auto',
  },
  cellContentWrapper: {
    display: 'flex',

    '&:hover': {
      '& $cellButtons': {
        opacity: 1,
      },
    },
  },
  tableCell: {
    '&:hover': {
      '& $cellButtons': {
        opacity: 1,
      },
    },
  }
}));

export default useStyles;
