import {
  useState, useMemo,
} from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';

import Input from 'components/Input';
import Drawer from '@mui/material/Drawer';

import logo from 'assets/images/newLogo.png';
import store from 'store/state';
import { clearToken } from 'utils/tokenControls';
import { appPaths } from 'routes';

import useStyles from './styles';

export default function PrimarySearchAppBar() {
  const history = useHistory()
  const dispatch = useDispatch();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const classes = useStyles({ isMobile });
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mainPaths = useMemo(() => appPaths.filter(path => !path.hidden), []);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handelLogout = (e: any) => {
    e.preventDefault();
    clearToken();
    dispatch(store.actions.setIsLoggedOut());
    handleMenuClose();
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handelLogout}>Logout</MenuItem>
    </Menu>
  );


  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu >
  );

  const toggleDrawer = () => {
    setDrawerOpen(prev => !prev);
  };

  const goToMainPage = () => {
    history.push('/');
  }
  return (
    <div className={classes.grow}>
      <div className={classes.header}>
        <img src={logo} height="42px" alt="amaros logo" onClick={goToMainPage} />
        {!isMobile ?
          (
            <>
              <nav className={classes.nav}>
                {mainPaths.map(({ path, title }) => (
                  <Tooltip title={title} key={path}>
                    <Link
                      to={path}
                      className={classes.routerLinkButton}
                    >
                      {title}
                    </Link>
                  </Tooltip>
                ))}
              </nav>
              <div>
                <Input
                  sx={{
                    bgcolor: '#02383c',
                    borderRadius: '4px',
                    margin: '0 15px',
                    minWidth: '300px',
                    boxSizing: 'content-box',
                    height: 35,
                  }}
                  placeholder="Patient Search"
                  InputProps={{
                    startAdornment: <SearchIcon color="inherit" />,
                    sx: {
                      color: 'white',
                      height: 35,
                      padding: '6.5px 16px 6.5px 6px',
                      '& Mui-input': {
                        // pl: 1,
                        height: 35,
                        padding: '6.5px 16px 6.5px 6px',
                      },
                    }
                  }}
                  size="small"
                />
              </div>
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
              {renderMobileMenu}
              {renderMenu}
            </>
          )
          : <>
            <Input
              sx={{
                bgcolor: '#02383c',
                borderRadius: '4px',
                margin: '0 15px',
                minWidth: isMobile || isIpad ? '150px' : '300px',
                boxSizing: 'content-box',
                height: 35,
              }}
              placeholder="Patient Search"
              InputProps={{
                startAdornment: <SearchIcon color="inherit" />,
                sx: {
                  color: 'white',
                  height: 35,
                  padding: '6.5px 16px 6.5px 6px',
                  '& Mui-input': {
                    // pl: 1,
                    height: 35,
                    padding: '6.5px 16px 6.5px 6px',
                  },
                }
              }}
              size="small"
            />
            <IconButton
              onClick={toggleDrawer}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer}
            >
              <nav className={classes.navMobile}>
                {mainPaths.map(({ path, title }) => (
                  <Tooltip title={title} key={path}>
                    <Link
                      to={path}
                      className={classes.routerLinkButtonMobile}
                    >
                      {title}
                    </Link>
                  </Tooltip>
                ))}
                <Link
                  to="/"
                  onClick={handelLogout}
                  className={classes.routerLinkButtonMobile}
                >
                  Log out
                </Link>
              </nav>
            </Drawer>
          </>}
      </div>


    </div>
  );
}
