import React from 'react';

import Grid from '@mui/material/Grid';

import { IGridItem } from './types';

function GridItem(props: IGridItem) {
  const { children, ...rest } = props;
  return (
    <Grid item {...rest }>
      { children }
    </Grid>
  );
}

export default GridItem;