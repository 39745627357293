import {
  useMemo,
  useState,
  useEffect,
  SyntheticEvent,
  useCallback,
  ChangeEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';

import useMount from 'hooks/useMount';
import Fetch from 'services/Fetch';
import { GET_SITES, GET_TRIALS } from 'configs/api';
import { actions } from 'store/actions';
import useMemoSelector from 'hooks/useMemoSelector';
import { getTrials } from 'store/selectors';

import TrialsSwitcher from './TrialsSwticher';
import Table from './Table';
import useStyles from './styles';
import { ISite } from 'utils/types';
// import { ITrialData } from 'store/state/types';

function List() {
  const dispatch = useDispatch();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const classes = useStyles({ isIpad, isMobile });
  const { push, location: { pathname } } = useHistory();
  const [modalIsOpen, setModalIsopen] = useState(false);
  const [sites, setSites] = useState<ISite[]>([]);
  const [selectedSite, setSelectedSite] = useState<string>('');
  const { trials } = useMemoSelector(state => ({
    trials: getTrials(state),
  }));

  const fetchTrials = useCallback(async (
    siteId = '',
    order = 'ASC',
    page = 1,
    take = 10,
  ) => {
    const results = await Fetch.get(`${GET_TRIALS}?order=${order}&page=${page}&take=${take}&siteId=${siteId}`);
    if (results.data) {
      dispatch(actions.setTrials({ data: results.data, meta: results.meta }))
    }
  }, [dispatch]);

  const getSites = async () => {
    const results = await Fetch.get(GET_SITES);
    const { headers, message, statusCode, status, ...rest } = results;
    if (statusCode === 401) {
      dispatch(actions.setIsNotAuthorized());
      return;
    }
    const sites: ISite[] = Object.values(rest);
    setSelectedSite(sites[0].id || '');
    setSites(sites);
  }

  useMount(() => {
    getSites();
  });

  useEffect(() => {
    selectedSite && fetchTrials(selectedSite)
  }, [selectedSite, fetchTrials]);

  const tableData = useMemo(() => (trials.data.map(trial =>
  ({
    ...trial, status: 'Pre-screening',
    connected: 'Not Connected',
  })
  )), [trials]);

  const toogleModal = () => setModalIsopen(prev => !prev);

  const pushToCreatePage = () => {
    push(`${pathname}/create`)
  }

  const gotToPage = useCallback((page: number) => {
    fetchTrials(selectedSite, undefined, page, trials.meta.take);
  }, [fetchTrials, selectedSite, trials]);

  const handelSiteChange = (e: SyntheticEvent, id: string) => {
    if ((e.target as HTMLInputElement).checked) {
      setSelectedSite(id);
    } else {
      setSelectedSite('');
    }
  }

  return (
    <>
      <div className={classes.contentWrapper}>
        <div className={classes.contentHeader}>
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {trials.meta.itemCount} trials
              <Typography variant="subtitle2" component="span" color="textSecondary">
                {' '} @ Macula & Retina Institute
              </Typography>
            </Typography>

          </div>
          <div className={classes.contentHeaderButtonGroup}>
            <Button onClick={toogleModal} variant="outlined">Manage trials</Button>
            <Button onClick={pushToCreatePage} variant="contained" sx={{ m: '0 10px' }}>Add trial</Button>
          </div>

        </div>
        <div className={classes.trialsTable}>
          <Table
            data={tableData}
            pageCount={trials.meta.pageCount}
            rowsPerPage={trials.meta.take}
            fetchTrials={(e: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => fetchTrials(selectedSite, undefined, undefined, e?.target.value)}
            gotToPage={gotToPage}
          />
        </div>
      </div>
      <Modal
        keepMounted
        open={modalIsOpen}
        onClose={toogleModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 5,
            maxHeight: '400px',
            overflowY: 'scroll',
          }}
        >
          <TrialsSwitcher
            onClose={toogleModal}
            sites={sites}
            handelSiteChange={handelSiteChange}
            selected={selectedSite}
          />
        </Box>
      </Modal>
    </>
  );
}

export default List;