import { Typography } from '@mui/material'

import FavoriteIcon from '@mui/icons-material/Favorite';

const getTrialStatus = (value: string) => () => (<span>{value}</span>);
const getDefaultContent = (value: string) => () => (<span>{value}</span>);
const getConnected = (value: string) => {
  if (value === 'Connected') {
    return (
      <>
        <Typography color="textSecondary" variant="body2" component="p" sx={{ display: 'flex', alignItems: 'center' }}>
          <FavoriteIcon fontSize="small" color="success" sx={{ mr: 1 }} />
          <span>Connected</span>
        </Typography>
      </>
    );
  }
  return (
    <>
      <Typography color="textSecondary" variant="body2" component="p" sx={{ display: 'flex', alignItems: 'center' }}>
        <FavoriteIcon fontSize="small" color="error" sx={{ mr: 1 }} />
        <span>Not connected</span>
      </Typography>
    </>
  );
}

export const cellRenderer = (column: string, value: any) => {
  switch (column) {
    case 'Trial status': return getTrialStatus(value as string);
    case 'Sponsor connected': return getConnected(value as string);
    default:
      return getDefaultContent(value as string);
  }
};
