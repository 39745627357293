// @ts-nocheck
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  grow: {
  },
  header: {
    display: 'flex',
    height: 55,
    minHeight: 55,
    background: theme.palette.primary.main,
    paddingLeft: 24,
    paddingRight: 24,
    alignItems: 'center',
    color: 'white',
    justifyContent: props => (props.isMobile ? 'space-between' : ''),
  },
  nav: {
    marginLeft: 20,
    height: 55,
    minHeight: 55,
    maxHeight: 55,
    padding: 0,
    // display: 'flex',

  },
  routerLinkButton: {
    display: 'inline-block',
    textDecoration: 'none',
    color: 'white',
    height: 55,
    lineHeight: '55px',
    padding: '0 20px',
    transition: 'all 200ms ease-out',
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  },
  searchCustom: {
    color: 'white',
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: '100px',
  },
  tabs: {
    overflow: 'visible',
    height: 55,
    minHeight: 55,
    maxHeight: 55,
  },
  tab: {
    width: 100,
    minWidth: 100,
    height: 55,
    minHeight: 55,
    maxHeight: 55,
    display: 'flex',
    flexDirection: 'row',
  },
  rchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    // background: 'white',
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
    },
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    alignSelf: 'center',
    justifyContent: 'end',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'end',
      marginLeft: 'auto',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  iconButton: {
    display: 'flex',
    alignItems: 'bottom',
  },
  routerLinkButtonMobile: {
    display: 'block',
    textDecoration: 'none',
    height: 55,
    lineHeight: '55px',
    padding: '0 20px',
    color: theme.palette.primary.main,
    transition: 'all 200ms ease-out',
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  },
  navMobile: {
    width: 250,
  }
}));

export default useStyles;
