// @ts-nocheck
import React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from 'components/Button';

import useStyles from './drawerStyle';
import {
  Accordion,
  AccordionSummary,
} from './Accordion';

function Menu({
  handleDrawerClose,
  open,
  onApply,
  resetFilters,
  patientId,
  selectPatientId,
  data,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Divider />
          <Autocomplete
            options={['34534', '45456', '58666']}
            id="patient_id"
            value={patientId}
            autoHighlight
            fullWidth
            onChange={(event, newValue) => {
              selectPatientId(newValue);
            }}
            renderInput={params => <TextField {...params} label="Patient Id" margin="normal" />}
          />
        </AccordionSummary>

      </Accordion>

      <Divider />
      <div className={classes.buttonHolder}>
        <Button onClick={resetFilters}>Reset</Button>
        <Button variant="contained" color="primary" onClick={onApply}>Show Results</Button>
      </div>
    </Drawer>
  );
}

export default Menu;
