export const retinaChart = {
  chart: {
    inverted: true,
    type: 'bullet',
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  plotOptions: {
    bullet: {
      targetOptions: {
        color: '#f7a35c',
      },
    },
    series: {
      pointPadding: 0.1,
      borderWidth: 0,
      color: '#f7a35c',
      targetOptions: {
        width: '200%',
      },
      pointInterval: 25,
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: ' Referable AMD',
    align: 'left',
  },
  yAxis: {
    gridLineWidth: 0.2,
    max: 1,
    plotBands: [{
      from: 0,
      to: 1,
      color: '#006d75',
    }],
    title: null,
    tickInterval: 0.25,
  },
  series: [{
    data: [{
      y: 0.8,
      target: 1,
    }],
  }],
};

export const glaucomaChart = {
  chart: {
    inverted: true,
    type: 'bullet',
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
    bullet: {
      targetOptions: {
        color: '#f7a35c',
      },
    },
    series: {
      pointPadding: 0.1,
      borderWidth: 0,
      color: '#f7a35c',
      targetOptions: {
        width: '200%',
      },
      pointInterval: 0.25,
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: 'Referable Glaucoma',
    align: 'left',
  },
  yAxis: {
    gridLineWidth: 0.2,
    max: 1,
    plotBands: [{
      from: 0,
      to: 1,
      color: '#006d75',
    }],
    title: null,
    tickInterval: 0.25,
  },
  series: [{
    data: [{
      y: 0.6,
      target: 1,
    }],
  }],
};

export const amdGrade = {
  legend: {
    enable: false,
  },
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: {
    text: 'Diabetic Retinopathy Grade',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: 'category',
    title: null,
  },
  yAxis: {
    title: null,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      // enableMouseTracking: false,
      labels: {
        enabled: false,
      },
      showInLegend: false,
    },
  },
  series: [
    {
      colorByPoint: true,
      data: [
        {
          name: 'None',
          y: 0,
          color: '#3c4043',
        },
        {
          name: 'Mild',
          y: 0.09,
          color: '#9e3c3e',
        },
        {
          name: 'Moderate',
          y: 0.86,
          color: '#006d75',
        },
        {
          name: 'Severe',
          y: 0.05,
        },
        {
          name: 'Proliferative',
          y: 0,
        },
      ],
    },
  ],
};
