import { useState, useMemo } from 'react';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined';

import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import { ISite } from 'utils/types';
import Fetch from 'services/Fetch';
import { CREATE_TRIAL, GET_SITES } from 'configs/api';
import useMount from 'hooks/useMount';
import useInput from 'hooks/useInput';

import useStyles from './styles';
import QueryMaker from './QueryMaker';
import Button from 'components/Button';
import { useHistory } from 'react-router';

function Create() {
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const styles = useStyles({ isMobile, isIpad });
  const [sites, setSites] = useState<ISite[]>([]);
  const [site, setSite] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const trial = useInput('');
  const { push } = useHistory();

  const getSites = async () => {
    const results = await Fetch.get(GET_SITES);
    const { headers, message, ...rest } = results;
    setSites(Object.values(rest));
  };

  useMount(() => {
    getSites();
  });

  const submitQuery = async (query: { condition: string, units: { [key: string]: any } }) => {
    const reqBody = {
      name: trial.value,
      siteId: site,
      query,
    };

    const result = await Fetch.post(CREATE_TRIAL, reqBody);
    if (result.id) {
      setOpen(true);
    }
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert sx={{ minWidth: 300 }} onClose={() => setOpen(false)} severity="success" elevation={6}>Trial Created</Alert>
      </Snackbar>
      <div className={styles.contentWrapper}>
        <Button onClick={() => push('/trials')}>
          <ArrowBackIosIcon />
          Go to List
        </Button>

        <div className={styles.root}>
          <div className={styles.rootFirstBlock}>
            <div className={styles.formWrapper}>
              <div className={styles.formIcon}>
                <FormatAlignLeftOutlinedIcon />
              </div>
              <Typography color="primary" variant="h6" align="center">
                New trial
              </Typography>
              <div>
                <div className={styles.formElWrapper}><Input fullWidth size="small" label="Trial Name" {...trial} /></div>
                {/* @ts-ignore */}
                <div className={styles.formElWrapper}> <Dropdown size="small" label="Therapeutic Area" /></div>
                {/* @ts-ignore */}
                <div className={styles.formElWrapper}><Dropdown size="small" label="Research Indications" /></div>
                <div className={styles.formElWrapper}>
                  <Dropdown
                    size="small"
                    label="Site"
                    value={site}
                    options={sites.map(site => ({ label: site.name, value: site.id }))}
                    onChange={e => setSite(e.target.value as string)}
                  />
                </div>
                <div className={styles.formElWrapper}><Input fullWidth size="small" label="Sponsor Name" /></div>
                <Accordion sx={{ maxWidth: 300, boxShadow: 'none', padding: 0 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    Additional Information
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ maxWidth: 300, boxShadow: 'none', padding: 0 }}
                  >
                    <div className={styles.formElWrapper}><Input fullWidth size="small" label="Protocol ID" /></div>
                    <div className={styles.formElWrapper}><Input fullWidth size="small" label="Site Number" /></div>
                    <div className={styles.formElWrapper}><Input fullWidth size="small" label="Principal Investigator" /></div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
          <div className={styles.rootSecondBlock}>
            <div className={styles.querymakerWrapper} style={{ width: '100%' }}>
              <div className={styles.formIcon}>
                <DynamicFormOutlinedIcon /> Add Trial information
              </div>
              <div />
              <QueryMaker submitQuery={submitQuery} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Create;

