// @ts-nocheck
/* eslint-disable max-len */
import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsReact from 'highcharts-react-official';
import Chart from 'react-google-charts';
import cloneDeep from 'lodash/cloneDeep';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import GridContainer from 'components/Grid/GridContainer';
// import GridItem from 'components/Grid/GridItem';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Dropdown from 'components/Dropdown';

import Fetch from 'services/Fetch';
import { GET_PATIENT } from 'configs/api';
// import useMount from 'hooks/useMount';

import useMemoSelector from 'hooks/useMemoSelector';
import {
  getPatients,
  // getSearchCriteria,
} from 'store/selectors';

import {
  diagnosisSeries,
  indexes,
  surgeriesIndexes,
  injectionsSurgeries,
  eyes,
  MenuProps,
  chartsMenuProps,
  rowsOD,
  rowsOS,
  chartNames,
  names,
} from './utils';
import Photos from './Photos';
import { diagnosisOptions, maculaOptions, iopOptions } from './chartsOptions';
import { AntTabs, AntTab } from './Tabs';
import ProgressionTable from './ProgrssionTable';

import useStyles from './styles';

HighchartsHeatmap(Highcharts);

export default function RecipeReviewCard() {
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const classes = useStyles({ isMobile, isIpad });
  const { state } = useLocation() || { state: {} };
  const { patientId = '00000000-0000-0000-0000-000000000091', matchPercentage } = state || {};
  const { push, goBack } = useHistory();
  const [patient, setPatient] = useState(() => ({}));
  const [patentIndex, setPatientIndex] = useState(0);
  const {
    patients,
    // searchCriteria: selectedFilters,
  } = useMemoSelector(state => ({
    patients: getPatients(state),
    // searchCriteria: getSearchCriteria(state),
  }));
  const { data: subjects } = patients;

  const [photosOpened, setPhotosOpened] = useState(false);
  const [selectedEye, setSelectedEye] = useState('left');
  const [diseases, setDiseases] = useState(() => ([
    'GA',
    'Wet AMD',
    'Dry AMD',
  ]));
  const [diseaseTab, setDiseaseTab] = useState(0);
  const [charts, setCharts] = useState(() => ([...chartNames]));

  const handleChange = event => {
    setDiseases(event.target.value);
  };

  const handleChartChange = event => {
    setCharts(event.target.value);
  };

  const getPatientData = useCallback(async id => {
    const result = await Fetch.get(`${GET_PATIENT}/${id}`);
    if (result) {
      setPatient({
        bday: result.dob,
        diagnosis: result.diagnosis,
        firstName: result.firstName,
        lastName: result.lastName,
        middleName: result.middleName,
        gender: result.gender,
        match: result.match,
        treatments: result.procedures,
        fakeDiagnosis: result.fakeDiagnosis,
        pdf: result.pdf,
        images: result.images.split(','),
      });
    }
  }, []);

  // const reFetchSubjects = useCallback(async () => {
  // const results = await Fetch.post(GET_PATIENT,}selectedFilters, { mode: 'no-cors' });
  // saveSubjectsData({ data: results?.data || [], totalCount: results?.meta?.itemCount });
  // }, [selectedFilters]);

  // useMount(() => {
  // if (!subjects || subjects.length === 0) {
  //   reFetchSubjects();
  // }
  // });

  useEffect(() => {
    patientId && getPatientData(patientId);
    const index = subjects?.findIndex(el => el.id === patientId);

    setPatientIndex(index);
  }, [patientId, getPatientData, subjects]);

  const handlePrintPDF = useCallback(() => {
    window.open(patient.pdf, '_blank');
  }, [patient]);

  const getPatient = useCallback(pointer => {
    const newIndex = pointer === 'next' ? patentIndex + 1 : patentIndex - 1;
    if (newIndex < 0 || newIndex > subjects.length - 1) return;
    const { id } = subjects[newIndex];
    push(`/subjects/${id}`, { patientId: id });
  }, [subjects, patentIndex, push]);

  const series = useMemo(() => (patient?.diagnosis?.reduce((acc, curr) => {
    const index = curr.date.split('-')[0];
    const eye = curr.location === 'OS' ? 'left' : 'right';
    // eslint-disable-next-line default-case
    switch (curr.name) {
      case 'Geographic Atrophy': acc[0][eye][indexes[index]] = curr.severity;
        break;
      case 'Wet AMD': acc[1][eye][indexes[index]] = curr.severity;
        break;
      case 'Dry AMD': acc[2][eye][indexes[index]] = curr.severity;
        break;
      case 'Diabetic Retinopathy': acc[3][eye][indexes[index]] = curr.severity;
        break;
      case 'Cataract': acc[4][eye][indexes[index]] = curr.severity;
        break;
    }
    return acc;
  }, cloneDeep(diagnosisSeries)) || cloneDeep(diagnosisSeries)), [patient]);

  const treatments = patient?.treatments?.reduce((acc, curr) => {
    const index = curr.date.split('-')[0];
    // eslint-disable-next-line default-case
    switch (curr.name) {
      case 'PRP': acc[2].data[surgeriesIndexes[index]] = true;
        break;
      case 'injection': acc[1].data[surgeriesIndexes[index]] = true;
        break;
      case 'Grid Laser': acc[0].data[surgeriesIndexes[index]] = true;
        break;
    }
    return acc;
  }, cloneDeep(injectionsSurgeries)) || cloneDeep(injectionsSurgeries);

  const [rightEye, leftEye] = useMemo(() => {
    const right = [[], []];
    const left = [[], []];

    patient.fakeDiagnosis?.forEach(({ macula, eye, iop }) => {
      if (eye) {
        right[0].push(macula);
        right[1].push(iop);
      } else {
        left[0].push(macula);
        left[1].push(iop);
      }
    });

    return [
      right.sort((a, b) => a - b),
      left.sort((a, b) => a - b),
    ];
  }, [patient.fakeDiagnosis]);

  const openImageFolder = () => {
    setPhotosOpened(true);
  };

  const selectEye = e => {
    setSelectedEye(e.target.value);
  };

  const selectedDiagnosis = series
    .map(({ name, left, right, color }) => ({ name, data: selectedEye === 'left' ? left : right, color }))
    .filter(item => diseases.includes(item.name));

  const handleDiseaseTabChange = useCallback((e, id) => {
    setDiseaseTab(id);
  }, []);

  return <>
    {photosOpened && <Photos close={() => setPhotosOpened(false)} images={patient.images} />}
    <GridContainer className={classes.clientSection} alignItems="flex-start" justifyContent="space-between">
      <div>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          ID:
          {' '}
          {patientId}
        </Typography>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          {patient.firstName}
          {' '}
          {patient.middleName}{'. '}
          {patient.lastName}
        </Typography>
        <Typography gutterBottom variant="body2" component="p" color="textSecondary">
          {patient.gender === 'F' ? 'Female, ' : 'Male, '}
        </Typography>
        <Typography gutterBottom variant="body2" component="p" color="textSecondary">
          Date of birth:
          {' '}
          {patient.bday}
        </Typography>
      </div>
      <div className={classes.buttonWrapper}>
        <div className={classes.progressBarWrapper}>
          <ProgressBar value={matchPercentage} />
        </div>
        <Button
          variant="contained"
          className={classes.referButtons}
          color="primary"
          onClick={handlePrintPDF}
          sx={{ margin: '0 10px' }}
        >
          Print OCT PDF
        </Button>
        <Button variant="contained" color="primary" className={classes.referButtons}>Refer</Button>
      </div>
    </GridContainer>
    <GridContainer justifyContent="space-between" direction="row" alignItems="center">
      <div>
        <div style={{ display: 'flex', alignItems: 'flex-end', padding: '0 20px', }}>
          <Button variant="contained" color="primary" className={classes.goBack} onClick={() => goBack()}>Go Back</Button>
          <Button sx={{ margin: '0 10px' }} variant="contained" color="primary" className={classes.goBack} onClick={openImageFolder}>Show Images</Button>
          <FormControl className={classes.chartsSelect}>
            <InputLabel id="checkbox-label">Charts</InputLabel>
            <Select
              labelId="checkbox-label"
              multiple
              value={charts}
              onChange={handleChartChange}
              input={<Input />}
              renderValue={selected => selected.join(', ')}
              MenuProps={chartsMenuProps}
            >
              {chartNames.map(name => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={charts.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.nextPrevPatient}>
        <Tooltip title="Previous Patient">
          <IconButton
            onClick={() => getPatient('prev')}
            aria-label="Previous Patient"
            size="large">
            <ChevronLeftIcon fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Next Patient">
          <IconButton onClick={() => getPatient('next')} aria-label="Next Patient" size="large">
            <ChevronRightIcon fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>

      </div>
    </GridContainer>
    <div className={classes.clientSection}>
      {charts.indexOf('Diagnosed diseases') > -1 && (
        <div className={classes.chartWrapperLeft}>
          <TabContext value={diseaseTab.toString()}>
            <AntTabs value={diseaseTab} onChange={handleDiseaseTabChange} aria-label="ant example">
              <AntTab label="Chart" />
              <AntTab label="Progression" />
            </AntTabs>
            <Paper>
              <TabPanel value="0" classes={{ root: classes.tabPanel }}>
                <HighchartsReact
                  options={{
                    ...diagnosisOptions,
                    series: cloneDeep(selectedDiagnosis),
                  }}
                  highcharts={Highcharts}
                />
                <div className={classes.selectionsWrapper}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">Disease</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={diseases}
                      onChange={handleChange}
                      input={<Input />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {names.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={diseases.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Dropdown
                    options={eyes}
                    onChange={selectEye}
                    label="Eye"
                    value={selectedEye}
                    name="eye"
                    fullWidth={false}
                  />
                </div>
              </TabPanel>
              <TabPanel value="1" classes={{ root: classes.tabPanel }}>
                <Typography gutterBottom variant="subtitle1" align="center" classes={{ subtitle1: classes.subtitle1 }}>
                  Disease progression
                </Typography>
                <ProgressionTable rows={selectedEye === 'left' ? rowsOS : rowsOD} />
                <div className={classes.selectionsWrapper}>
                  <Dropdown
                    options={eyes}
                    onChange={selectEye}
                    label="Eye"
                    value={selectedEye}
                    name="eye"
                    fullWidth={false}
                  />
                </div>
              </TabPanel>
            </Paper>
          </TabContext>
        </div>
      )}
      {charts.indexOf('Macula Thickness - Foveal') > -1 && (
        <div className={classes.chartWrapperRight}>
          <div style={{ minHeight: '48px' }} />
          <Paper>
            <HighchartsReact
              options={{
                ...maculaOptions,
                series: [
                  {
                    name: 'OD',
                    data: rightEye[0],
                  },
                  {
                    name: 'OS',
                    data: leftEye[0],
                  },
                ],
              }}
              highcharts={Highcharts}
            />
          </Paper>
        </div>
      )}
    </div>
    <div className={classes.clientSection} >
      {charts.indexOf('IOP') > -1 && (
        <div className={classes.chartWrapperLeft}>
          <Paper>
            <HighchartsReact
              options={{
                ...iopOptions,
                series: [
                  {
                    name: 'OD',
                    data: rightEye[1],
                  },
                  {
                    name: 'OS',
                    data: leftEye[1],
                  },
                ],
              }}
              highcharts={Highcharts}
            />
          </Paper>
        </div>
      )}
      {charts.indexOf('Treatments - Injection & Surgeries') > -1 && (
        <div className={classes.chartWrapperRight}>
          <Paper>
            <Typography gutterBottom variant="subtitle1" align="center" classes={{ subtitle1: classes.subtitle1 }}>
              Treatments - Injections & Surgeries
            </Typography>
            <Chart
              chartType="Table"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  { type: 'string', label: '' },
                  { type: 'boolean', label: '2016' },
                  { type: 'boolean', label: '2017' },
                  { type: 'boolean', label: '2018' },
                  { type: 'boolean', label: '2019' },
                  { type: 'boolean', label: '2020' },
                  { type: 'boolean', label: '2021' },
                ],
                ['Grid Laser', ...treatments[0].data],
                // ['Grid Laser - OS', ...treatments[0].left],
                ['Injection', ...treatments[1].data],
                // ['Injection - OS', ...treatments[1].left],
                ['PRP', ...treatments[2].data],
                // ['PRP - OS', ...treatments[2].left],
              ]}
              options={{
                width: '100%',
                height: '100%',
                alternatingRowStyle: true,
                cssClassNames: {
                  headerRow: classes.treatmentsTableHeder,
                  headerCell: classes.treatmentsTableHederCell,
                  tableCell: classes.treatmentsTableCell,
                  hoverTableRow: classes.hoverTableRow,
                },
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          </Paper>
        </div>
      )}
    </div>
  </>;
}
