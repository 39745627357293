const indexes = {
  2021: 8,
  2020: 7,
  2019: 6,
  2018: 5,
  2017: 4,
  2016: 3,
  2015: 2,
  2014: 1,
  2013: 0,
};

const surgeriesIndexes = {
  2021: 5,
  2020: 4,
  2019: 3,
  2018: 2,
  2017: 1,
  2016: 0,
};

const diagnosisSeries = [
  {
    name: 'GA',
    right: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    left: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    color: '#9e3c3e',
  },
  {
    name: 'Wet AMD',
    right: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    left: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    color: '#006d75',
  },
  {
    name: 'Dry AMD',
    right: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    left: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    color: '#355574',
  },
  {
    name: 'DR',
    right: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    left: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    color: '#748c4a',
  },
  {
    name: 'Cataract',
    right: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    left: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    color: '#aa621d',
  },
];

const injectionsSurgeries = [
  {
    name: 'Grid Laser',
    data: [false, false, false, false, false, false],
  },
  {
    name: 'Injection',
    data: [false, false, false, false, false, false],
  },
  {
    name: 'PRP',
    data: [false, false, false, false, false, false],
  },
];

const diseaseOptions = [
  {
    label: 'Geographic Atrophy',
    value: 'Geographic Atrophy',
  },
  {
    label: 'Wet AMD',
    value: 'Wet AMD',
  },
  {
    label: 'Dry AMD',
    value: 'Dry AMD',
  },
  {
    label: 'DR',
    value: 'DR',
  },
  {
    label: 'Cataract',
    value: 'Cataract',
  },
  {
    label: 'All',
    value: 'All',
  },
];

const eyes = [
  {
    label: 'OS',
    value: 'left',
  },
  {
    label: 'OD',
    value: 'right',
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const chartsMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'fit-content',
    },
  },
};

const names = [
  'GA',
  'Wet AMD',
  'Dry AMD',
  'DR',
  'Cataract',
];

function createData(name: any, calories: any, fat: any) {
  return { name, calories, fat };
}

const rowsOD = [
  createData('Wet AMD', '23%', '18%'),
  createData('GA', '100%', '100%'),
];

const rowsOS = [
  createData('Wet AMD', '100%', '100%'),
  createData('GA', '81%', '93%'),
];
const chartNames = [
  'Diagnosed diseases',
  'Macula Thickness - Foveal',
  'IOP',
  'Treatments - Injection & Surgeries',
];

export {
  diagnosisSeries,
  indexes,
  injectionsSurgeries,
  diseaseOptions,
  eyes,
  surgeriesIndexes,
  MenuProps,
  chartsMenuProps,
  createData,
  rowsOD,
  rowsOS,
  chartNames,
  names,
};
