import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 'auto',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    padding: theme.spacing(3),
    width: '100%',
  },
  contentShift: {
    [theme.breakpoints.up('md')]: {

      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
  reportDataHeader: {
    margin: '10px 0',
  },
  diseaseDesc: {
    marginBottom: 30,
  },
}));

export const useTableStyles = makeStyles((theme: Theme) => ({
  sortIcons: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellContent: {
    display: 'flex',
    minWidth: 'max-content',
    alignItems: 'center',
  },
  cellContentHeader: {
    display: 'flex',
    minWidth: 'max-content',
    alignItems: 'center',

  },
  cellButtons: {
    opacity: 0,
    transition: 'all 200ms ease-in',
    marginLeft: 'auto',
  },
  cellContentWrapper: {
    display: 'flex',
    minWidth: 'max-content',
    '&:hover': {
      '& $cellButtons': {
        opacity: 1,
      },
    },
  },
  tableCell: {
    '&:hover': {
      '& $cellButtons': {
        opacity: 1,
      },
    },
  }
}));

export default useStyles;
