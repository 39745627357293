import { nanoid } from 'nanoid'

import { baseURL } from 'configs/api';
import objectFromEntries from 'utils/objectFromEntries';
import getEntries from 'utils/getEntries';
import addPropertyToObject from 'utils/addValuePropertyTo';
import { IUnit } from 'utils/types';
import Fetch from 'services/Fetch';


import {
  IUnitConjunctionItem,
  ConjuctionItem,
  IUnitState
} from './types';

interface IUnitNames {
  'diagnose': string;
  'familyHistory': string;
  'medication': string;
  'procedure': string;
  'socialInformation': string;
  'allergy': string;
  'visualAcuity': string;
  [key: string]: string;
}

// keep consistant with backend
interface IUnitFormElementNames {
  'age': string;
  'smokingDuration': string;
  'alcoholStatus': string;
  'drugStatus': string;
  'smokingStatus': string;
  'code': string;
  'relative': string;
  'duration': string;
  'eye': string;
  'severity': string;
  'sex': string;
  'gender': string;
  'race': string;
  'ethnicity': string;
  'date': string,
  'year': string,
  [key: string]: any;
}
export const unitNames: IUnitNames = {
  'diagnose': 'Diagnose',
  'familyHistory': 'Family history',
  'medication': 'Medication',
  'procedure': 'Procedure',
  'socialInformation': 'Social Information',
  'allergy': 'Allergy',
  'visualAcuity': 'Visual Acuity',
};

export const unitFormElementsNames: IUnitFormElementNames = { //keep consistante with backend
  'age': 'Age',
  'smokingDuration': 'Smoking Duration',
  'alcoholStatus': 'Alcohol Status',
  'drugStatus': 'Drug Status',
  'smokingStatus': 'Smoking Status',
  'code': 'Code',
  'duration': 'Duration',
  'eye': 'Eye',
  'severity': 'Severity',
  'sex': 'Sex',
  'gender': 'Gender',
  'race': 'Race',
  'ethnicity': 'Ethnicity',
  'relative': 'Relative',
  'drugDuration': 'Drud duration',
  'alcoholDuration': 'Alcohol Duration',
  'lessThan': 'Less Than',
  'moreThan': 'More Than',
  'year': 'Year',
  'yearsAgo': 'Years Ago',
  'date': 'Date',
};

function returnUnitObject(type: ConjuctionItem) {
  return type === ConjuctionItem.Group ? defaultUnitGroup : defaultUnitItem;
}

function getObjectWithChildrens(objects: IUnitConjunctionItem) {
  return Object.values(objects).filter(object => 'children' in object);
}

function addNewItemToObject(id: string, objects: IUnitConjunctionItem, type: ConjuctionItem) {
  objects[id] = {
    ...objects[id],
    children: {
      ...objects[id].children,
      [nanoid()]: returnUnitObject(type),
    },
  }
}

export function addUnitITemtoObject(id: string, objects: IUnitConjunctionItem, type: ConjuctionItem) {
  if (id in objects) {
    addNewItemToObject(id, objects, type);
    return;
  }

  const nextObjects = getObjectWithChildrens(objects);
  nextObjects.length && nextObjects.forEach(nextObject => addUnitITemtoObject(id, nextObject.children, type));
};

function removeItemFromObject(id: string, objects: IUnitConjunctionItem) {
  if (id in objects) {
    const entries = getEntries(objects).filter(([key]) => key !== id);
    return objectFromEntries(entries);
  }
  return objects;
}


export function removeUnitITemFromObject(id: string, objects: IUnitConjunctionItem) {
  const tempObj = removeItemFromObject(id, objects)
  Object.keys(tempObj).forEach(key => {
    if (typeof tempObj[key] === 'object') {
      tempObj[key] = removeUnitITemFromObject(id, tempObj[key]);
    }
  });
  return tempObj;
};

export const defaultstate: IUnitConjunctionItem = {
  type: 'group',
  conjunction: 'and',
  children: {},
  id: nanoid(),
};

export const defaultUnitGroup: IUnitConjunctionItem = {
  type: 'group',
  conjunction: 'and',
  children: {},
};

export const defaultUnitItem: IUnitConjunctionItem = {
  type: 'rule',
  value: '',
};

const defaultCodeOption = [
  {
    code: '',
    createdAt: '',
    id: '',
    name: '',
    updatedAt: '',
  }
];

export const reConstructData = (data: IUnit) => {
  const constructedData: [string, any][] = getEntries(data).map(([key, value]) => {
    const updatedValues: [string, any][] = getEntries(value).map(([propKey, values]) => {
      const isBoolean = values.type === 'bool';
      const isText = values.type === 'text';
      let propName = isBoolean ? 'checked' : 'value';
      const objectWIthAddedProp = addPropertyToObject(values, propName, isBoolean ? false : '');

      return [propKey, isText ? addPropertyToObject(objectWIthAddedProp, 'options', defaultCodeOption) : objectWIthAddedProp];
    });

    return [key, objectFromEntries(updatedValues)];
  });
  return objectFromEntries(constructedData);
};

export function updateConjuctionValue(id: string, object: IUnitConjunctionItem, value: 'and' | 'or') {
  if (id in object) {
    object[id].conjunction = value;
    return;
  }

  const nextObjects = getObjectWithChildrens(object as IUnitConjunctionItem);
  nextObjects.length && nextObjects.forEach(nextObject => updateConjuctionValue(id, nextObject.children, value));
};

export function updateConjuctionRuleValue(id: string, object: IUnitConjunctionItem, value: string) {
  if (id in object) {
    object[id].value = value;
    return;
  }

  const nextObjects = getObjectWithChildrens(object as IUnitConjunctionItem);
  nextObjects.length && nextObjects.forEach(nextObject => updateConjuctionRuleValue(id, nextObject.children, value));
};

export function creatConjunctionQuery(data: IUnitConjunctionItem) {
  let query = '(';
  const operator = data.conjunction === 'and' ? '&&' : '||';
  Object.values(data.children as IUnitConjunctionItem).forEach(({ type, ...rest }: IUnitConjunctionItem, index: number) => {
    const sign = index !== 0 ? ` ${operator}` : ''
    if (type === 'rule') {
      query = `${query}${sign} ${rest.value}`;
    } else if (type === 'group') {
      query = `${query}${sign} ${creatConjunctionQuery({ type, ...rest })}`;
    }
  });
  return `${query})`;
};

export const fetchOptions = async (query: string, seachPath: string) => {
  const url = `${baseURL}${seachPath}?page=1&take=10&q=${query}`
  return Fetch.get(url);
};

export const getRequestObjectFrom = (data: IUnitState) => {
  return Object.entries(data).reduce((acc, curr) => {
    const key = curr[0];
    const { unitName, ...rest } = curr[1];
    const data = Object.entries(rest).reduce((acc, curr) => {
      const key = curr[0];
      const { type, value, checked } = curr[1];
      const isNumber = type === 'int';
      const isBoolen = type === 'bool';
      const endValue = isNumber ? Number(value) : value;
      acc[key] = isBoolen ? checked || undefined : endValue || undefined;
      return acc;
    }, {} as { [key: string]: any });
    acc[key] = {
      [unitName]: data,
    };
    return acc;
  }, {} as { [key: string]: any });
};
