import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import useMemoSelector from 'hooks/useMemoSelector';
import { getPatients, getSearchCriteria } from 'store/selectors';
import Fetch from 'services/Fetch';
import { SEARCH_PATIENTS } from 'configs/api';
import useMount from 'hooks/useMount';
import { actions } from 'store/actions';

import Column from './Column';
import useStles from './styles';

const columns = [
  {
    title: 'Potential Candidate',
    data: [],
    id: 1,
  },
  {
    title: 'Pre-Screening',
    data: [],
    id: 2,
  },
  {
    title: 'In-screening',
    data: [],
    id: 3,
  },
  {
    title: 'Actively Enrolled',
    data: [],
    id: 4,
  },
];

const removeFrom = (fromArr: any[], startIndex: number) => {
  const result = Array.from(fromArr);
  result.splice(startIndex, 1);
  return result;
};

const insertFromTo = (toArr: any[], fromArr: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(fromArr);
  const endRes = Array.from(toArr);
  const [removed] = result.splice(startIndex, 1);
  endRes.splice(endIndex, 0, removed);
  return endRes;
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function TrialDetails() {
  const classes = useStles();
  const dispatch = useDispatch();
  const [cols, setCols] = useState<any[]>(columns);
  const [loading, setLoading] = useState(true);

  const {
    patients,
    searchCriteria,
  } = useMemoSelector(state => ({
    patients: getPatients(state),
    searchCriteria: getSearchCriteria(state),
  }));

  console.log(patients)

  const fetchTrialSubjects = async () => {
    const reqBody = {
      units: searchCriteria.units,
      condition: searchCriteria.query,
      page: 1,
      take: 50,
    };
    const res = await Fetch.post(SEARCH_PATIENTS, reqBody)
    // @ts-ignore
    if (res.status === 200) {
      console.log(res);
      dispatch(actions.setPatients({ data: res.data, meta: res.meta }));
      setCols(cols => (cols.map(col => (col.id === 1 ? { ...col, data: res.data } : col))));
    }
    setLoading(false);
  };

  useMount(() => {
    fetchTrialSubjects()
  });

  const onDragEnd = (dropResult: DropResult) => {
    const { type, destination, source } = dropResult;
    if (!destination) return;

    if (type === 'column') {
      const nextOrder = reorder(cols, source.index, destination.index);
      setCols(nextOrder);
    }
    if (type === 'row') {
      const { droppableId: destCol, index: destIndx } = destination;
      const { droppableId: sourceCol, index: sourceIndx } = source;
      const startId = sourceCol.split("-").pop();
      const endId = destCol.split("-").pop();
      if (startId === endId) {
        setCols(cols => (
          cols.map(col => col.id === Number(endId) ? {
            ...col,
            data: reorder(col.data, sourceIndx, destIndx)
          } : col)
        ));
      } else {
        const source = cols.find(col => col.id === Number(startId))?.data;
        setCols(cols => (
          cols.map(col => col.id === Number(endId)
            ? {
              ...col,
              data: insertFromTo(col.data, source as any[], sourceIndx, destIndx),
            }
            : col.id === Number(startId)
              ? {
                ...col,
                data: removeFrom(col.data, sourceIndx)
              }
              : col)
        ));
      }
    }
  }

  const onScrollFetch = useCallback((...rest) => {
    console.log('here');
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.detailHeader}>
        <Typography color="primary">
          A 52-week multicenter, randomized, double-masked, 2-arm parallel study to compare efficacy, safety and immunogenicity of SOK583A1 to Eylea®, administered intravitreally, in patients with neovascular age-related macular degeneration
        </Typography>
      </div>
      <div className={classes.content}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-for-columns" type="column" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.droppableForCol}
                {...provided.droppableProps}
              >
                {cols.map(({ data, title, id }, index) => (
                  <Draggable draggableId={title} index={index} key={title}>
                    {(provided, snapshot) => (
                      <div
                        className={classes.draggableCol}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                      >
                        <Column onScrollFetch={onScrollFetch} patientsMetaData={index === 0 ? patients.meta : null} loading={loading} header={title} patients={data} id={id as number} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div >
  );
}

export default TrialDetails;
