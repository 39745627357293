// @ts-nocheck

import React from 'react';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  text: {
    marginLeft: 5,
  },
}));

export default function UploadButton() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          <PhotoCamera />
          <span className={classes.text}>Upload</span>
        </Button>
      </label>
    </div>
  );
}
