import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 55px)',
    minHeight: 'calc(100% - 55px)',
  },
  detailHeader: {
    background: 'white',
    padding: 10,
  },
  droppableForCol: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    overflowX: 'auto',
    padding: 20,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,

    }
  },
  draggableCol: {
    boxShadow: theme.shadows[3],
    margin: '0 10px 0 0',
    background: 'white',
    minWidth: 250,
    cursor: 'default',
  },
  content: {
    height: '100%',
  }
}));

export const useColmnStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  header: {
    height: 60,
    lineHeight: '60px',
    background: theme.palette.grey[100],
    boxShadow: theme.shadows[1],
    padding: '0 10px',
    color: theme.palette.text.secondary,
    fontWeight: 600,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  counter: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    width: 'max-content',
    padding: '0 4px',
    border: `1px solid ${theme.palette.grey[400]}`,
    height: 30,
    textAlign: 'center',
    lineHeight: '30px',
    cursor: 'pointer',
  },
  patientItem: {
    padding: '10px 20px',
    color: theme.palette.grey[400],
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },

  draggableItem: {
    boxShadow: theme.shadows[6],
  },
  colDroppableZone: {
    height: 'calc(100vh - 250px)',
    overflowY: 'scroll',
  }

}));

export default useStyles;
