import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  loginPage: {
    height: 'calc(100vh)',
    background: theme.palette.primary.main,
  },
  gridStyles: {
    textAlign: 'center',
    width: '100%',
  },
  inputWrapper: {
    marginTop: 25,
  },
  buttonWrapper: {
    marginTop: 20,
  },
  forgotButton: {
    fontSize: 12,
  },
  loginHeader: {
    marginBottom: 20,
  },
  rememberMe: {
    padding: '4px 8px',
    width: '100%',
  },
  logo: {
    marginBottom: 20,
    width: '100%',
  },
  formWrapper: {
    width: 300,
    background: 'white',
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow: theme.shadows[10],
  },
  loginButton: {
    width: '100%',
    background: theme.palette.primary.light,
    transition: 'all 200ms ease-out',
    textAlign: 'center',
    color: 'white',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
    },
    '&:active': {
      background: theme.palette.primary.dark,
    }
  },
  formContent: {
    padding: 20,
  }
}));

export default useStyles;
