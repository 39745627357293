import { ChangeEvent, useState, MouseEvent } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Popover from '@mui/material/Popover';

import Input from 'components/Input';

function SearchFilter({
  // Tdo fix typeing
  // @ts-ignore
  column: { filterValue, setFilter },
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value || undefined);
  };

  const handleProfileMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <FormControl sx={{ m: 0, ml: 'auto' }} onClick={e => e.stopPropagation()}>
      <IconButton
        size="small"
        edge="end"
        onClick={handleProfileMenuOpen}
      >
        <FilterAltOutlinedIcon fontSize="small" />
      </IconButton>
      <Popover
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Input
          onChange={handleChange}
          value={filterValue}
          placeholder="Patient Search"
          InputProps={{
            startAdornment: <SearchOutlinedIcon color="inherit" />,
            sx: {
              '& Mui-input': {
                pl: 1,

              },
            }
          }}
          size="small"
        />
      </Popover>
    </FormControl>
  )
}

export default SearchFilter;