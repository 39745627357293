// @ts-check

import { ChangeEvent, SyntheticEvent } from 'react';
import { IUnitOptions, IUnitSingleProp, IUnitCodeOptions } from "utils/types";
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/useAutocomplete';

export interface IStateItem extends IUnitOptions {
  'unitName': string;
  [key: string]: any;
}
export interface IUnitState {
  [key: string]: IStateItem;
}

export interface IUnitLinks {
  data: string[];
  handleUnitItemSelect: formOnChange
  include?: boolean;
  exclude?: boolean;
  addItem: (id: string, type: ConjuctionItem) => void;
  removeItem: (id: string) => void;
  conjunctions: IUnitConjunctionItem;
  changeGroupOperator: (id: string, operatorName: 'and' | 'or') => void;
  chooseFromAutoSuggestion: autoSuggestoinSelectWrapper;
}

export type autoSuggestoinSelect = (
  event: ChangeEvent<{}>,
  value: string | IUnitCodeOptions | null,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<IUnitCodeOptions> | undefined
) => void;

export type autoSuggestoinSelectWrapper = (
  event: ChangeEvent<{}>,
  value: string | IUnitCodeOptions | string[] | null,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<IUnitCodeOptions | string> | undefined,
  id?: string,
) => void;

export type formOnChange = (e: ChangeEvent<Element> | ChangeEvent<{ name?: string | undefined; value: unknown; }>, subFieldId?: string) => void;

export interface IForm {
  data: IUnitOptions;
  name: string;
  id: string;
  onChangeHandler: (event: ChangeEvent<Element> | ChangeEvent<{ name?: string | undefined; value: unknown; }>, id: string, subId?: string) => void;
  chooseFromAutoSuggestion: autoSuggestoinSelectWrapper;
  removeUnit: (event: SyntheticEvent, id: string) => void;
}

interface IFormElementData extends IUnitSingleProp {
  name: string;
}

export interface IFormElement {
  data: IFormElementData;
  onChange: formOnChange;
  options?: any[];
  chooseFromAutoSuggestion?: autoSuggestoinSelect;
}

export interface IUnitConjunctionItem {
  'id'?: string;
  'type'?: 'group' | 'rule',
  'value'?: string;
  'conjunction'?: 'and' | 'or',
  'children'?: IUnitConjunctionItem | {};
  [key: string]: any;
}

export enum ConjuctionItem {
  Group = 'group',
  Item = 'item',
}

export interface IUnitConditionItem {
  options: string[];
  onChange: autoSuggestoinSelectWrapper;
  enableDelete: boolean;
  removeItem: (id: string) => void;
  id: string;
  defaultValue?: string;
}

export interface IUnitConditionGroup {
  addItem: (id: string, type: ConjuctionItem) => void;
  removeItem: (id: string) => void;
  unitNames: string[];
  id: string;
  data: IUnitConjunctionItem;
  enableDelete?: boolean;
  chooseFromAutoSuggestion: autoSuggestoinSelectWrapper;
  changeGroupOperator: (id: string, operatorName: 'and' | 'or') => void;
  conjunction: string;
}
