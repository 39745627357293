// @ts-nocheck
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: props => props.isMobile || props.isIpad ? '' : '350px 1fr',
    gridTemplateRows: props => props.isMobile || props.isIpad ? 'auto' : '',
    gap: 20,
    marginTop: 20,
  },
  rootFirstBlock: {
  },
  rootSecondBlock: {
  },
  formWrapper: {
    marginTop: 20,
    background: 'white',
    display: 'grid',
    gridTemplateRows: '30px 1fr 40px',
    gap: 10,
    borderRadius: 4,
    padding: 20,
    minWidth: 'fit-content',
    maxHeight: 'fit-content',
    width: 'fit-content',
    boxShadow: theme.shadows[2],
    position: 'relative',
  },
  querymakerWrapper: {
    boxShadow: theme.shadows[2],
    position: 'relative',
    minWidth: 'fit-content',
    width: '100%',
    marginTop: 20,
    borderRadius: 4,
    background: 'white',
    display: 'grid',
    padding: 20,
    gridTemplateRows: '30px 1fr',
  },
  formIcon: {
    position: 'absolute',
    boxShadow: theme.shadows[5],
    top: -20,
    left: 20,
    minWidth: 65,
    width: 'fit-content',
    padding: '0 20px',
    height: 65,
    background: theme.palette.primary.main,
    color: 'white',
    opacity: 1,
    zIndex: 11,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  formElWrapper: {
    margin: '10px 0',
    width: 300,
    maxWidth: 300,
  },
  accardionSummary: {
    width: 300,
    maxWidth: 300,
  },
  contentWrapper: {
    padding: 20,

  }
}));

export default useStyles;
