// @ts-nocheck
import {
  useState,
  useMemo,
  // useEffect,
  // forwardRef,
} from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux';

import MaUTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
// import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';


import {
  // usePagination,
  // useRowSelect,
  useSortBy,
  useTable,
  useFilters,
} from 'react-table';

import { localStorageApi } from 'services/Storage';
import { actions } from 'store/actions';
import Fetch from 'services/Fetch';
import { GET_TRIALS } from 'configs/api';

import Dropdown from 'components/Dropdown';
// import TablePaginationActions from './Pagination';
import SelectFilter from './SelectFilter';
import SearchFilter from './SearchFilter';
import DeleteModal from './DeleteModal';
import { cellRenderer } from '../utils';

import { useTableStyles } from '../styles';

const rowsStyle = {
  cursor: 'pointer',
  '&:hover': {
    bgcolor: 'grey.100'
  }
};

const cellStyle = {
  color: 'primary.light',
};

const headStyles = {
  bgcolor: 'grey.100',
  '&:hover': {
    bgcolor: 'grey.200',
  },
};

const EnhancedTable = ({
  data,
  pageCount,
  rowsPerPage,
  fetchTrials,
  gotToPage,
}) => {
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);
  const classes = useTableStyles({ isMobile, isIpad });
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [trialId, setTrialId] = useState('');
  const handleRowAction = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleDeleteTrial = (e: MouseEvent, trialId) => {
    e.stopPropagation();
    setOpen(true);
    setTrialId(trialId);
  };
  const handleDelete = async () => {
    const res = await Fetch.delete(`${GET_TRIALS}/${trialId}`)
    setOpen(false);
    if (res.status === 204) {
      setOpenSnackbar(true);
      fetchTrials();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Trial name',
        accessor: 'name',
        Filter: SearchFilter,
        canFilter: true,
        actions: (trial) => (
          <>
            <Typography component="span" color="grey.300">|</Typography>
            <IconButton onClick={handleRowAction}>
              <Tooltip title="Tiral Information">
                <InfoOutlinedIcon color="primary" />
              </Tooltip>
            </IconButton>
            <Typography component="span" color="grey.300">|</Typography>
            <IconButton onClick={handleRowAction}>
              <Tooltip title="Patient log">
                <AssignmentOutlinedIcon color="primary" />
              </Tooltip>

            </IconButton>
            <IconButton onClick={handleRowAction}>
              <Tooltip title="Edit trial">

                <CreateOutlinedIcon color="primary" />
              </Tooltip>

            </IconButton>
            <IconButton onClick={(e) => handleDeleteTrial(e, trial.original.id)}>
              <Tooltip title="Delete trial">
                <DeleteIcon color="error" />
              </Tooltip>
            </IconButton>
          </>
        ),
      },
      {
        Header: 'Trial status',
        accessor: 'status',
        Filter: SelectFilter,
        filter: (rows, id, filterValues) => {
          if (filterValues.length === 0) return rows;
          return rows.filter(r => filterValues.includes(r.values[id]));
        },
        canFilter: true,
        id: 'status'
      },
      {
        Header: 'Sponsor connected',
        accessor: 'connected',
        Filter: SelectFilter,
        disableSortBy: true,
        filter: (rows, id, filterValues) => {
          if (filterValues.length === 0) return rows;
          return rows.filter(r => filterValues.includes(r.values[id]));
        },
        canFilter: true,
      },
    ],
    []
  );

  const defaultFilters = useMemo(() => ([{ id: 'status', value: [] }]), []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

  } = useTable(
    {
      columns,
      data,
      // filterTypes,
      // defaultColumn,
      initialState: { filters: defaultFilters },
    },
    useFilters,
    useSortBy,
  );

  const goToTrial = (row) => {
    const { condition, units } = row.original.query;
    const searchCryteria = {
      units,
      query: condition,
    };
    localStorageApi.putSync('searchCriteria', searchCryteria);

    dispatch(actions.setSearchCrytieria({
      data: searchCryteria,
    }));
    push(`/trial/${row.original.id}`);
  };

  const closeModal = () => {
    setTrialId('');
    setOpen(false);
  };

  return (
    <>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert sx={{ minWidth: 300 }} onClose={() => setOpen(false)} severity="success" elevation={6}>Trial Deleted</Alert>
      </Snackbar>
      <DeleteModal open={open} handleClose={closeModal} handleDelete={handleDelete} />
      <TableContainer sx={{ bgcolor: 'white', boxShadow: 3, }}>
        {/* <TableToolbar
          numSelected={Object.keys(selectedRowIds).length}
          deleteUserHandler={deleteUserHandler}
          addUserHandler={addUserHandler}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        /> */}
        <MaUTable {...getTableProps()}>
          <TableHead sx={headStyles}>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell align="right" {...(column.getHeaderProps(column.getSortByToggleProps()))}>
                    <div className={classes.cellContent}>
                      <div className={classes.cellContentHeader}>
                        {column.render('Header')}
                        <span className={classes.sortIcons}>
                          {column.isSorted ?
                            column.isSortedDesc
                              ? <KeyboardArrowUpIcon fontSize="small" color="primary" />
                              : <KeyboardArrowDownIcon fontSize="small" color="primary" />
                            : null
                          }
                        </span>
                      </div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return <TableRow {...row.getRowProps()} sx={rowsStyle} onClick={() => goToTrial(row)}>
                {row.cells.map(cell => {
                  return (
                    <TableCell align="right" {...cell.getCellProps()} sx={cellStyle} className={classes.tableCell}>
                      <div className={classes.cellContentWrapper}>
                        <Typography color="inherit">
                          {cell.render(cellRenderer(cell.column.Header, cell.value))}
                        </Typography>
                        <div className={classes.cellButtons}>{cell.column.actions?.(row)}</div>
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Dropdown
                  label="Rows per page"
                  value={rowsPerPage}
                  onChange={fetchTrials}
                  options={[{ value: 5, label: 5 }, { value: 10, label: 10 }, { value: 20, label: 20 }]}
                />
              </TableCell>
              <TableCell />

              <TableCell align="right">
                <Pagination
                  count={pageCount}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  onChange={(e, page) => gotToPage(page)}
                  sx={{
                    display: 'inline-block',
                    '& > ul': {
                      flexWrap: isMobile ? 'nowrap' : 'wrap',
                    }
                  }}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
    </>
  )
}

export default EnhancedTable;