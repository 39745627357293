
// import { createSelector } from 'reselect';

import { IAppState, IPatientsData, ISearchCriterias, ITrialsData } from './../state/types';
import { IReduxState } from '../types';

export const getState = (state: IReduxState): IAppState => state.appState;
export const getIsLoggedIn = (state: IReduxState): boolean => getState(state).isLoggedIn;
export const getPatients = (state: IReduxState): IPatientsData => getState(state).patients;
export const getTrials = (state: IReduxState): ITrialsData => getState(state).trials;
export const getSearchCriteria = (state: IReduxState): ISearchCriterias => getState(state).searchCriteria;