import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { mainPaths } from 'routes';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material';
import Authorize from 'components/Authorize';
import theme from 'assets/theme';
import store from 'store';


declare module '@mui/material' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <Authorize>
              <Switch>
                {mainPaths.map(({ component: Component, path, key }) => (
                  <Route
                    key={key}
                    path={path}
                    render={routeProps => {
                      if (!Component) return null;
                      //@ts-ignore
                      return <Component {...routeProps} />
                    }}
                  />
                ))}
                <Redirect to={mainPaths[0].path} />
              </Switch>
            </Authorize>
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
