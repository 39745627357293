import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 100,
  },
  progressRoot: {
    height: 10,
    borderRadius: 5,
  },
  progressBar: {
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

export default useStyles;
