import {
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from 'react';

type handler = (e: SyntheticEvent) => void;
export interface IUseInput {
  value: string,
  setValue: Dispatch<SetStateAction<string>>;
  hasValue: boolean;
  clear: () => void;
  onChange: handler;
}
const useInput = (initial: string, onChangeHandler?: handler): IUseInput => {
  const [value, setValue] = useState(initial);
  const onChange = useCallback(e => {
    onChangeHandler?.(e);
    setValue(e.target.value);
  }, [onChangeHandler]);

  return {
    value,
    setValue,
    hasValue:
      value !== undefined
      && value !== null
      && (typeof value !== 'number' ? value.trim && value.trim() !== '' : true),
    clear: useCallback(() => setValue(''), []),
    onChange,
  };
};

export default useInput;
