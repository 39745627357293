import { useState, useCallback, useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import Button from 'components/Button';

import ConjunctionButton from './ConditionButton';
import Item from './UnitConditionItem';
import { unitGroupStyles } from './style';
import { IUnitConjunctionItem, IUnitConditionGroup, ConjuctionItem } from './types';

function Group({
  addItem,
  unitNames,
  changeGroupOperator,
  id,
  data,
  removeItem,
  enableDelete = false,
  chooseFromAutoSuggestion,
  conjunction,
}: IUnitConditionGroup) {
  const {
    root,
    buttonsHolder,
    contentHolder,
    controlButtons,
  } = unitGroupStyles();
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const children: [string, IUnitConjunctionItem][] = Object.entries(data);
  const handleOnMouseEnter = useCallback(() => {
    if (isMobile || isIpad) return;
    setShowButtons(true);
  }, [isMobile, isIpad]);

  const handleOnMouseLeave = useCallback(() => {
    if (isMobile || isIpad) return;
    setShowButtons(false);
  }, [isMobile, isIpad]);

  const hanldechangeOperator = useCallback((name) => {
    changeGroupOperator(id, name);
  }, [changeGroupOperator, id]);
  return (
    <div className={root}>
      <div
        className={buttonsHolder}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <ConjunctionButton onClick={hanldechangeOperator} defaultState={conjunction} />
        {(showButtons || isMobile || isIpad) && (
          <div className={controlButtons}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addItem(id, ConjuctionItem.Item)}> Add Rule
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addItem(id, ConjuctionItem.Group)}> Add Group
            </Button>
            {enableDelete && (
              <Button
                onClick={() => removeItem(id)}
              >
                <DeleteIcon color="error" />
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={contentHolder}>
        {!!children.length && children.map(child => {
          return child[1].type === 'rule'
            ? <Item
              key={child[0]}
              id={child[0]}
              removeItem={removeItem}
              enableDelete
              defaultValue={child[1].value}
              options={unitNames}
              onChange={chooseFromAutoSuggestion}
            />
            : <Group
              unitNames={unitNames}
              data={child[1].children as IUnitConjunctionItem}
              conjunction={child[1].conjunction as string}
              id={child[0]}
              key={child[0]}
              addItem={addItem}
              removeItem={removeItem}
              enableDelete
              changeGroupOperator={changeGroupOperator}
              chooseFromAutoSuggestion={chooseFromAutoSuggestion}
            />
        })}
      </div>

    </div>
  );
}

export default Group;
