import { useState, ChangeEvent } from 'react';


function useInputChange(defaultValue: string) {
  const [value, setValue] = useState(defaultValue);
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }
  return {
    value,
    onChange,
  };
}

export default useInputChange;