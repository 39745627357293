import React, { useMemo, useState, useCallback, SyntheticEvent } from 'react';

import { nanoid } from 'nanoid'
import cloneDeep from 'lodash/cloneDeep';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

import { IUnit, IUnitCodeOptions } from 'utils/types';
import getEntries from 'utils/getEntries';
// import getIncrementStringCode from 'utils/getIncrementingStringCode';
import Fetch from 'services/Fetch';
import useMount from 'hooks/useMount';
import { GET_UNITS } from 'configs/api';
import { UnitGroups, Form } from 'components/QueryMaker';
import objectFromEntries from 'utils/objectFromEntries';
import { getRequestObjectFrom } from 'components/QueryMaker/utils';

import useStyles from './style';
import { ConjuctionItem, IStateItem, IUnitConjunctionItem, IUnitState } from './types';
import {
  unitNames,
  reConstructData,
  defaultstate,
  addUnitITemtoObject,
  defaultUnitGroup,
  defaultUnitItem,
  removeUnitITemFromObject,
  updateConjuctionValue,
  creatConjunctionQuery,
  updateConjuctionRuleValue,
  fetchOptions,
} from './utils';


const defaultUnitOPtions: IUnit = {};

interface IQueryMaker {
  submitQuery: (query: {
    condition: string;
    units: { [key: string]: any }
  }) => void;
}
function Search({ submitQuery }: IQueryMaker) {
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const classes = useStyles({ isMobile, isIpad });
  const [selectedOption, setSelectedoption] = useState('');
  const [unitOptions, setUnitOptions] = useState<IUnit>(defaultUnitOPtions);
  const dropdownOptions = useMemo(() => Object.keys(unitOptions).map(optionName => ({ label: unitNames[optionName] || optionName, value: optionName })), [unitOptions]);
  const [conjunctionsInclude, setConjunctionsInclude] = useState<IUnitConjunctionItem>(defaultstate);
  const [conjunctionsExclude, setConjunctionsExclude] = useState<IUnitConjunctionItem>(defaultstate);
  const [units, setUnits] = useState<IUnitState>({});
  const data = useMemo(() => reConstructData(unitOptions), [unitOptions]);

  const onOptionChoose = useCallback((e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    setSelectedoption(e.target.value as string);
  }, []);

  const getUnits = useCallback(async () => {
    const results = await Fetch.get(GET_UNITS);
    if (results) {
      const { headers: extractThis, status: ignore, ...rest } = results;
      setUnitOptions(rest);
    }
  }, []);

  useMount(() => {
    getUnits();
  });

  const addUnit = useCallback(() => {
    if (!selectedOption) return;
    const newunit = cloneDeep(data[selectedOption]);
    setUnits(prev => {
      const unitsCount = Object.keys(prev).filter(key => key.includes(unitNames[selectedOption])).length;
      return {
        [`${unitNames[selectedOption]} ${unitsCount + 1}`]: {
          ...newunit,
          unitName: selectedOption,
          unitLabel: unitNames[selectedOption],
          queryLabel: `${unitNames[selectedOption]} ${unitsCount + 1}`.replace(' ', '_'),
        },
        ...prev,
      };
    });
  }, [data, selectedOption]);

  const unitsArr: [string, IStateItem][] = getEntries(units);
  const onChangeHandler = useCallback(async (e, unitId: string, subUnitId?: string) => {
    const { name, value, type, checked } = e.target;
    const isText = type === 'text';
    let options: IUnitCodeOptions | [] = [];
    if (isText) {
      const { data } = await fetchOptions(value, e.target.getAttribute('data-search'));
      if (data.length) {
        options = data;
      }
    }

    setUnits(prevState => {
      const prevUnit = prevState[unitId];
      const prevUnitUpdatingProp = prevUnit[subUnitId || name];

      const newState = {
        ...prevState,
        [unitId]: {
          ...prevUnit,
          [subUnitId || name]: {
            ...prevUnitUpdatingProp,
            ...(subUnitId ?
              {
                [name]: {
                  ...prevUnitUpdatingProp[name],
                  ...(type === 'checkbox' ? { checked } : { value }),
                },
              }
              : {}),
            ...(!subUnitId && (type === 'checkbox' ? { checked } : { value })),
            ...(!subUnitId && isText && { options })
          },
        },
      };
      return newState;
    });
  }, []);

  const handleAddingConjunction = useCallback((updateState: React.Dispatch<React.SetStateAction<IUnitConjunctionItem>>, id: string, type: ConjuctionItem) => {
    updateState(prev => {
      if (prev.id === id) {
        return {
          ...prev,
          children: {
            ...prev.children,
            [nanoid()]: type === ConjuctionItem.Group ? defaultUnitGroup : defaultUnitItem,
          }
        };
      }
      const newState = cloneDeep(prev);
      addUnitITemtoObject(id, newState.children as IUnitConjunctionItem, type);
      return newState;
    })
  }, []);

  const addConjunction = useCallback((id: string, type: ConjuctionItem, updateInclusions: boolean) => {
    if (updateInclusions) {
      handleAddingConjunction(setConjunctionsInclude, id, type);
    } else {
      handleAddingConjunction(setConjunctionsExclude, id, type);
    }
  }, [handleAddingConjunction])

  const removeConjunction = useCallback((id, fromInclusions) => {
    if (fromInclusions) {
      setConjunctionsInclude(prevState => removeUnitITemFromObject(id, cloneDeep(prevState)));
    } else {
      setConjunctionsExclude(prevState => removeUnitITemFromObject(id, cloneDeep(prevState)));
    }
  }, []);

  const handleChangeGroupOperator = useCallback((updateState: React.Dispatch<React.SetStateAction<IUnitConjunctionItem>>, id: string, name: 'and' | 'or',) => {
    updateState(prev => {
      if (prev.id === id) {
        return {
          ...prev,
          conjunction: name,
        };
      }

      const newState = cloneDeep(prev);
      updateConjuctionValue(id, newState.children as IUnitConjunctionItem, name);
      return newState;
    });
  }, []);

  const changeGroupOperator = useCallback((id: string, name: 'and' | 'or', include) => {
    if (include) {
      handleChangeGroupOperator(setConjunctionsInclude, id, name);
    } else {
      handleChangeGroupOperator(setConjunctionsExclude, id, name);
    }
  }, [handleChangeGroupOperator]);

  const chooseFromAutoSuggestion = useCallback((e, selectedData, reason, details, unitId) => {
    if (!selectedData) return;
    setUnits(prevState => {
      const prevUnit = prevState[unitId];
      const prevUnitUpdatingProp = prevUnit.code;
      return {
        ...prevState,
        [unitId]: {
          ...prevUnit,
          code: {
            ...prevUnitUpdatingProp,
            value: selectedData.id,
          },
        },
      };
    });
  }, []);

  const handleChooseRuleItem = useCallback((
    updateState: React.Dispatch<React.SetStateAction<IUnitConjunctionItem>>,
    selectedData,
    id,
  ) => {
    updateState(prev => {
      if (prev.id === id) {
        return {
          ...prev,
          value: selectedData,
        };
      }

      const newState = cloneDeep(prev);
      updateConjuctionRuleValue(id, newState.children as IUnitConjunctionItem, selectedData);
      return newState;
    });
  }, []);

  const chooseCunjuntionRuleItem = useCallback((e, selectedData, reason, details, id, updateInclusions) => {
    if (updateInclusions) {
      handleChooseRuleItem(setConjunctionsInclude, selectedData, id);
    } else {
      handleChooseRuleItem(setConjunctionsExclude, selectedData, id);
    }
  }, [handleChooseRuleItem]);


  const query = useMemo(() => {
    const inclusionQuery = creatConjunctionQuery(conjunctionsInclude);
    const exclusionQuery = `!${creatConjunctionQuery(conjunctionsExclude)}`;
    const hasExclusions = exclusionQuery.length > 3;
    const query = hasExclusions ? `${inclusionQuery} && ${exclusionQuery}` : inclusionQuery;
    const endResult = query.replaceAll('&&', 'AND').replaceAll('||', 'OR');

    return endResult.length > 2 ? endResult : '';
  }, [conjunctionsInclude, conjunctionsExclude]);

  const removeUnit = useCallback((e: SyntheticEvent, id: string) => {
    e.stopPropagation();
    setUnits(prev => {
      const newState = objectFromEntries(getEntries(prev).filter(unit => unit[0] !== id));
      return newState;
    });
  }, []);

  const handleSubmitQuery = () => {
    const inclusionQuery = creatConjunctionQuery(conjunctionsInclude);
    const exclusionQuery = `!${creatConjunctionQuery(conjunctionsExclude)}`;
    const params = getRequestObjectFrom(units);
    const hasExclusions = exclusionQuery.length > 3;
    const hasInclusion = inclusionQuery.length > 3;
    const AND = hasExclusions && hasInclusion ? ' && ' : '';
    let query = hasInclusion ? inclusionQuery : '';
    query = hasExclusions ? `${query}${AND}${exclusionQuery}` : query;
    const refactoredParams = Object.fromEntries(Object.entries(params).map(([key, value]) => ([key.replaceAll(' && ', '--AND--')
      .replaceAll(' || ', '--OR--')
      .replaceAll(' ', '_')
      .replaceAll('--AND--', ' && ')
      .replaceAll('--OR--', ' || '), value])));
    const searchCriteria = {
      units: refactoredParams,
      condition: query
        .replaceAll(' && ', '--AND--')
        .replaceAll(' || ', '--OR--')
        .replaceAll(' ', '_')
        .replaceAll('--AND--', ' && ')
        .replaceAll('--OR--', ' || ')
    };
    submitQuery(searchCriteria);
  };

  return (
    <div>
      <div className={classes.controlls}>
        <Button
          onClick={addUnit}
          variant="outlined"
          color="primary"
          size="small"
        >
          Add Unit
        </Button>
        <Dropdown
          options={dropdownOptions}
          onChange={onOptionChoose}
          label="Unit identifier"
          labelId="units"
          value={selectedOption}
          name="Unit identifier"
          fullWidth
          size='small'
          variant="standard"
        />
      </div>
      <Accordion sx={{ margin: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ margin: 0, height: 30, }}
        >
          <Typography variant="body1" component='p' color="textSecondary">
            Units
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {unitsArr.map(([id, { unitName, unitLabel, queryLabel, ...rest }]) => (
            <Form
              key={id}
              data={rest}
              name={unitLabel || unitName}
              removeUnit={removeUnit}
              id={id}
              onChangeHandler={onChangeHandler}
              chooseFromAutoSuggestion={chooseFromAutoSuggestion}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ margin: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ margin: 0, height: 30, }}
        >
          <Typography variant="body1" component='span' color="textSecondary">
            Search Conjunctions
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <div className={classes.conjunctionWrapper}>
            <UnitGroups
              addItem={(...rest) => addConjunction(...rest, true)}
              removeItem={(...rest) => removeConjunction(...rest, true)}
              data={Object.keys(units)}
              handleUnitItemSelect={() => null}
              include
              conjunctions={conjunctionsInclude}
              changeGroupOperator={(...rest) => changeGroupOperator(...rest, true)}
              chooseFromAutoSuggestion={(...rest) => chooseCunjuntionRuleItem(...rest, true)}
              key="include"
            />
            <UnitGroups
              addItem={(...rest) => addConjunction(...rest, false)}
              removeItem={(...rest) => removeConjunction(...rest, false)}
              data={Object.keys(units)}
              handleUnitItemSelect={() => null}
              exclude
              key="exclude"
              conjunctions={conjunctionsExclude}
              changeGroupOperator={(...rest) => changeGroupOperator(...rest, false)}
              chooseFromAutoSuggestion={(...rest) => chooseCunjuntionRuleItem(...rest, false)}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <div style={{ padding: 20, display: 'flex', justifyContent: 'space-between' }}>
        <Typography>
          {query}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component="span"
          size="small"
          onClick={handleSubmitQuery}
        >
          Add new Trial
        </Button>
      </div>
    </div>
  );
}

export default Search;
